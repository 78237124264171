.page-template-contact {
}
.page-template-contact .page-top-text p {
    max-width: none;
}

//
// contact-data layout
//
.contact-row {
    display: grid;
    grid-template-rows: repeat(1, minmax(0, 1fr));
    grid-gap: 20px;

    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(3, auto);
        grid-gap: 20px;
    }

    @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-gap: 20px;
        margin-top: 30px;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 50px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media-breakpoint-up(xxxl) {
        grid-template-columns: repeat(3, auto);
    }
}

.contact-data {
    position: relative;
    overflow: hidden;
    padding: 20px;
    background: rgba(#fff, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: $border-radius;

    @include media-breakpoint-up(md) {
        padding: 20px 30px;
    }
}

.contact-data > svg {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(10%, 10%);
    color: rgba(#fff, 0.1);
    font-size: 120px;
    z-index: 0;
}

.contact-data-3 {
}

//
// contact-data styles
//
.contact-data p {
    margin-bottom: 10px;
    position: relative;
    z-index: 1;
}

.contact-data p:last-child {
    margin-bottom: 0;
}

.contact-data .map-link {
    font-size: 14px;
    margin-top: 5px;
    display: inline-block;
}

.contact-data *:last-child {
    margin-bottom: 0;
}

//
// Form + map
//
.contact-row-2 {
    @include media-breakpoint-up(xxl) {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 80px;
    }
}

//
// Form
//
.contact-page-form {
    margin-top: 30px;

    @include media-breakpoint-up(md) {
        margin-top: 50px;
    }
}

.contact-page-form .contact-form > h2 {
    margin-bottom: 15px;
}

.contact-page-form .contact-form > h2 + p {
    @include media-breakpoint-up(lg) {
        margin-bottom: 30px;
    }
}

// form
.contact-page-form .contact-form .form-inputs {
    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    @include media-breakpoint-up(xl) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media-breakpoint-up(xl) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

.contact-page-form .form-inputs .form-group:nth-child(n + 3) {
    @include media-breakpoint-up(md) {
        grid-column: 1 / -1;
    }
}

.contact-page-form .form-inputs .form-group:nth-child(n + 4) {
    @include media-breakpoint-up(md) {
        grid-column: 1 / -1;
    }
}

.contact-page-form .contact-form .invalid-tooltip {
    position: static;
}

//
// Map
//
.contact-page-map {
    margin-top: 30px;

    @include media-breakpoint-up(md) {
        margin-top: 50px;
    }
}

.contact-page-map > h2 {
    margin-bottom: 15px;
}

.contact-page-map > h2 + p {
    margin-bottom: 15px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 30px;
    }
}

.contact-page-map iframe {
    width: 100%;
}
