.footer {
}

.footer-links-wrapper {
    @include media-breakpoint-up(md) {
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-gap: 0;
        border-top: 1px solid var(--border-color-2);
    }
}

.footer-links {
    @include media-breakpoint-down(sm) {
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-bottom: 1px solid var(--border-color-2);
    }

    @include media-breakpoint-up(md) {
        border-right: 1px solid var(--border-color-2);
        padding: 20px 5px 20px 20px;
    }

    @include media-breakpoint-up(xxl) {
        padding: 30px 5px 30px 30px 30px;
    }

    @include media-breakpoint-up(xxxl) {
        padding: 50px 5px 50px 54px;
    }
}

.footer-links-1 {
    @include media-breakpoint-down(sm) {
        padding-top: 30px;
        border-top: 1px solid var(--border-color-2);
    }

    @include media-breakpoint-between(md, xl) {
        padding-left: 0;
    }
}

.footer-links-3 {
    border-bottom: 0;
    padding-bottom: 0;

    @include media-breakpoint-up(md) {
        border-right: 0;
        padding-right: 0;
    }
}

.footer-links-3 .social-media {
    margin-top: 20px;
}

.footer-links-3 .social-media .social-media-link {
    width: auto;
}

.footer-links-3 .social-media .social-media-icon {
    font-size: 30px;
    display: flex;
    flex: 0 0 auto;
    width: 40px;
    text-align: left;
    position: relative;
    top: -1px;
    margin-right: 5px;
    margin-left: -10px;
}

.social-media-link-text {
    line-height: 1;
}

.footer-links h3 {
    font-size: rem-calc(20px);
    font-family: $font-family-sans-serif;
    margin-bottom: 18px;
}

.footer-links h3 a:not(:hover) {
    color: var(--body-color);
}
