.header-options-wrapper {
    background: var(--background-1);
    color: var(--color-4);
    padding-top: 10px;
    padding-bottom: 10px;

    @include media-breakpoint-down(md) {
        border-bottom: 1px solid var(--border-1);
    }

    @include media-breakpoint-up(lg) {
        position: fixed;
        left: 0;
        right: 0;
        top: calc(10px + var(--space-adjustment-from-top));
        padding-top: 0;
        padding-bottom: 0;
        z-index: 111;
        background: none;
    }

    @include media-breakpoint-up(xl) {
        @include min-screen-height(700px) {
            top: calc(30px + var(--space-adjustment-from-top));
        }
    }

    @include media-breakpoint-up(xl) {
        left: auto;
        right: calc(var(--space-adjustment-from-side, 0px) + 10px);
    }

    @include media-breakpoint-up(xxxl) {
        top: calc(10px + var(--space-adjustment-from-top));
        right: calc(var(--space-adjustment-from-side, 0px) + 30px);

        @include min-screen-height(700px) {
            top: calc(30px + var(--space-adjustment-from-top));
        }
    }
}

.header-options-wrapper .header-options {
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-up(lg) {
        justify-content: flex-end;
        padding-right: 10px;
    }

    @include media-breakpoint-up(xl) {
        padding-right: 0;
    }

    @include media-breakpoint-up(xxxl) {
        padding-right: 0;
    }
}

.header-options-contrast,
.header-options-font {
    display: flex;
    align-items: center;

    @include media-breakpoint-up(lg) {
        align-items: stretch;
    }
}

.header-options-contrast-label,
.header-options-font-label {
    padding-right: 10px;
    border-right: 0;

    @include media-breakpoint-up(lg) {
        border: 2px solid var(--primary);
        background: var(--primary);
        color: var(--color-1);
        padding-left: 10px;
        display: flex;
        align-items: center;
    }
}

.header-options-contrast-label {
    @include media-breakpoint-up(lg) {
        border-radius: 5px 0 0 5px;
    }
}

.header-options-contrast button,
.header-options-font button {
    border: 2px solid var(--primary);
    background: #fff;
    @include media-breakpoint-down(sm) {
        border-radius: 5px;
    }
}

.header-options-font button {
    @include media-breakpoint-up(lg) {
        border-radius: 0 5px 5px 0;
    }
}

.header-options-font button span {
    margin-left: 3px;
    margin-right: 3px;
    position: relative;
}

.header-options-font button span:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background: #000;
    transition: 0.3s;
    opacity: 0;
}

.header-options-font button span:nth-child(1) {
    font-size: rem-calc(14px);
}

.header-options-font button span:nth-child(2) {
    font-size: rem-calc(16px);
}

.header-options-font button span:nth-child(3) {
    font-size: rem-calc(18px);
}
