// root variables
:root {
    --body-color: #{$body-color};
    --body-color-2: #{$body-color-2};

    --body-bg: #{$body-bg};
    --body-bg-2: #fff;

    --hamburger-color: var(--body-color-2);

    --border-color: #{$border-color};
    --border-color-2: #{$border-color-2};
    --border-color-3: #{$border-color-3};
    --border-color-4: #{$border-color-4};

    --hr-bg: rgba(255, 255, 255, 0.3);

    --tertiary: #{$tertiary};
    --green-3: #{$green-3};
    --side-space: #{$grid-gutter-width/2};
    --custom-hover-overlay-bg: #{rgba($primary, 0.5)};
    --mm-page-dim-opacity: 0.6;
    --mm-color-background: var(--primary);
    --placeholder-bg: #{$gray-200};
    --scroll-higlight-color: #{lighten($primary, 20%)};
    --hamburger-layer-width: 30px;
    --link-decoration-color: #{$link-color};
    --link-decoration-color-hover: #{$link-hover-color};
    --swiper-pagination-bullet-horizontal-gap: #{$bullets-gap};

    --link-color: #9ebf06;
    --link-hover-color: #9ebf06;
    --link-color-2: var(--primary);
    --link-color-3: #fff;

    --scrollbar-width: 7px;
    --scrollbar-height: 7px;
    --scrollbar-foreground: var(--secondary);
    --scrollbar-background: var(--body-bg); //#354f29;

    //--header-item-width: 38px;
    //--header-item-height: 38px;

    --header-item-width: 55px;
    --header-item-height: 44px;

    --background-1: #fff;
    --background-2: #fff;
    --background-3: #000;

    --color-1: #fff;
    --color-2: #fff;
    --color-3: #222;
    --color-4: #222;

    --border-1: #fff;

    --nav-link-background: #fff;
    --nav-link-color: #222;
    --nav-link-hover-background: #fff;
    --nav-link-hover-color: #{$gray-600};
    --nav-icon-color: var(--secondary);
    --nav-icon-hover-color: var(--secondary);
    --nav-link-selected-color: #{$gray-600};
    --nav-link-selected-background: #fff;
    --dropdown-link-background: var(--secondary);
    --dropdown-link-color: #fff;
    --dropdown-triangle-color: var(--secondary);
    --dropdown-link-hover-background: var(--tertiary);
    --dropdown-link-hover-color: #fff;
    --dropdown-triangle-hover-color: var(--tertiary);

    --tooltip-bg: var(--secondary);
    --tooltip-color: #fff;

    --bullet-background: #fff;
    --bullet-border-color: #fff;
    --bullet-active-background: var(--secondary);
    --bullet-active-border-color: var(--secondary);

    --stripe-color: var(--secondary);

    --styled-alert-color: #000;
    --styled-alert-danger-background: #f7cccc;
    --styled-alert-success-background: #cbe5d1;

    --space-adjustment-from-top: 0px;

    @include media-breakpoint-up(md) {
        //--header-item-width: 50px;
        //--header-item-height: 50px;
        --header-item-width: 58px;
        --header-item-height: 54px;
    }

    @include media-breakpoint-up(lg) {
        --header-width: 280px;
        --side-space: 60px;
        --header-item-width: 60px;
        --header-item-height: 56px;
    }

    @include media-breakpoint-up(xl) {
        --side-space: 80px;
    }

    @include media-breakpoint-up(xxxl) {
        --side-space: 106px;
    }
}

.ccm-toolbar-visible:root {
    --space-adjustment-from-top: 48px;
}
