.silnet-logo-container {

    .silnet-logo-image {
        width: auto;
        height: 30px;
        display: inline-block;
        vertical-align: middle;

        filter: grayscale(1);
        opacity: $silnet-logo-image-opacity;

        animation-duration: 4s;
        animation-iteration-count: 1;
        animation-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);

        @include basic-transition;

        &.animate {
            animation-name: 'silnet-logo-image-animation';
        }
    }

    &.silnet-dark-bg {

        .silnet-logo-image {
            filter: grayscale(1) invert(1);

            &.animate {
                animation-name: 'silnet-dark-bg-logo-image-animation';
            }
        }
    }

    a {
        border: 0 !important;
        text-decoration: none !important;

        &:hover {

            .silnet-logo-image {
                filter: grayscale(0) invert(0) !important;
                opacity: 1 !important;
            }
        }
    }

}

@keyframes silnet-logo-image-animation {

    0% {
        transform: rotate3d(0, 1, 0, 0deg);
        filter: grayscale(1);
        opacity: $silnet-logo-image-opacity;
    }

    25% {
        filter: grayscale(1);
        opacity: $silnet-logo-image-opacity;
    }

    60%,
    80% {
        transform: rotate3d(0, 1, 0, 360deg);
        filter: grayscale(0);
        opacity: 1;
    }

    85% {
        transform: rotate3d(0, 1, 0, 360deg) scale(1.2);
    }

    90% {
        transform: rotate3d(0, 1, 0, 360deg) scale(1);
    }

    95% {
        transform: rotate3d(0, 1, 0, 360deg) scale(1.2);
    }

    95% {
        filter: grayscale(0);
        opacity: 1;
    }

    100% {
        transform: rotate3d(0, 1, 0, 360deg) scale(1);
        filter: grayscale(1);
        opacity: $silnet-logo-image-opacity;
    }
}

@keyframes silnet-dark-bg-logo-image-animation {

    0% {
        transform: rotate3d(0, 1, 0, 0deg);
        filter: grayscale(1) invert(1);
        opacity: $silnet-logo-image-opacity;
    }

    25% {
        filter: grayscale(1) invert(1);
        opacity: $silnet-logo-image-opacity;
    }

    60%,
    80% {
        transform: rotate3d(0, 1, 0, 360deg);
        filter: grayscale(0) invert(0);
        opacity: 1;
    }

    85% {
        transform: rotate3d(0, 1, 0, 360deg) scale(1.2);
    }

    90% {
        transform: rotate3d(0, 1, 0, 360deg) scale(1);
    }

    95% {
        transform: rotate3d(0, 1, 0, 360deg) scale(1.2);
    }

    95% {
        filter: grayscale(0) invert(0);
        opacity: 1;
    }

    100% {
        transform: rotate3d(0, 1, 0, 360deg) scale(1);
        filter: grayscale(1) invert(1);
        opacity: $silnet-logo-image-opacity;
    }
}
