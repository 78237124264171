/* stylelint-disable rule-empty-line-before, indentation */
@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

@mixin fluid(
    $min-value,
    $max-value,
    $property: 'font-size',
    $min-vw: ps,
    $max-vw: xxxl,
    $force-px: false
) {
    $u1: unit(map-get($grid-breakpoints, $min-vw));
    $u2: unit(map-get($grid-breakpoints, $max-vw));
    $u3: unit($min-value);
    $u4: unit($max-value);

    @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
        & {
            #{$property}: if($force-px, $min-value, rem-calc($min-value));

            @media screen and (min-width: map-get($grid-breakpoints, $min-vw)) {
                #{$property}: calc(
                    #{if($force-px, $min-value, rem-calc($min-value))} + #{strip-unit(
                            $max-value - $min-value
                        )} *
                        (
                            (100vw - #{map-get($grid-breakpoints, $min-vw)}) / #{strip-unit(
                                    map-get($grid-breakpoints, $max-vw) -
                                        map-get($grid-breakpoints, $min-vw)
                                )}
                        )
                );
            }

            @media screen and (min-width: map-get($grid-breakpoints, $max-vw)) {
                #{$property}: if($force-px, $max-value, rem-calc($max-value));
            }
        }
    }
}

/* stylelint-enable rule-empty-line-before, indentation */
