.page-info .breadcrumbs-container {
    @include media-breakpoint-up(lg) {
        margin-top: 30px;
        margin-bottom: 16px;
    }
}

.page-info .with-stripe:after {
    margin-top: 20px;
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
        margin-top: 36px;
        margin-bottom: 40px;
    }
}

.ccm-page .page-title-long {
    @include fluid(34px, 50px);
    @include fluid(38px, 54px, 'line-height');
}
