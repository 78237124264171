.clear_float:before,
.clear_float:after {
    display: table;
    content: ' ';
}

.clear_float:after {
    clear: both;
}

span.sr-only {
    display: none;
}

.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 22px 0;
    border-radius: 2px;
}

.pagination > li {
    display: inline;
}

.pagination > li > a,
.pagination > li > span {
    position: relative;
    float: left;
    padding: 8px 16px;
    line-height: 1.42857143;
    text-decoration: none;
    color: #3f9edc;
    background-color: #fff;
    border: 1px solid var(--body-bg);
    margin-left: -1px;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
    margin-left: 0;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
    color: #2076af;
    background-color: #eee;
    border-color: var(--body-bg);
}

.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
    z-index: 2;
    color: #fff;
    background-color: #3f9edc;
    border-color: #3f9edc;
    cursor: default;
}

.pagination > .disabled > span,
.pagination > .disabled > span:hover,
.pagination > .disabled > span:focus,
.pagination > .disabled > a,
.pagination > .disabled > a:hover,
.pagination > .disabled > a:focus {
    color: #777;
    background-color: #fff;
    border-color: var(--body-bg);
    cursor: not-allowed;
}

// Style below
.ccm-page .pagination {
    padding: 0;
    margin: 0;
    float: none;
    overflow: hidden;
    display: block;
}

.ccm-page .pagination > li > a {
    color: #8a8a8a;
    background-color: #fff;
    border: 1px solid var(--body-bg);
    transition: 0.3s;
}

.ccm-page .pagination > li > a:hover,
.ccm-page .pagination > li > a:focus {
    color: #fff;
    background: lighten($primary, 10%);
    border-color: var(--body-bg);
    text-decoration: none;
}

.ccm-page .pagination > .active > a,
.ccm-page .pagination > .active > span,
.ccm-page .pagination > .active > a:hover,
.ccm-page .pagination > .active > span:hover,
.ccm-page .pagination > .active > a:focus,
.ccm-page .pagination > .active > span:focus {
    background: var(--primary);
    border-color: var(--primary);
}

.ccm-page .pagination > .disabled > span,
.ccm-page .pagination > .disabled > span:hover,
.ccm-page .pagination > .disabled > span:focus,
.ccm-page .pagination > .disabled > a,
.ccm-page .pagination > .disabled > a:hover,
.ccm-page .pagination > .disabled > a:focus {
    background: #e6e6e6;
    color: #a9a9a9;
}

// border radius
$bdrs: 2px;

.ccm-page .pagination > li:first-child > a,
.ccm-page .pagination > li:first-child > span {
    border-radius: $bdrs 0 0 $bdrs;
}

.ccm-page .pagination > li:last-child > a,
.ccm-page .pagination > li:last-child > span {
    border-radius: 0 $bdrs $bdrs 0;
}

.ccm-page .pagination > li.prev,
.ccm-page .pagination > li.next {
    @include media-breakpoint-down(sm) {
        display: none;
    }
}
