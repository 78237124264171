.mm-navbar-inner-1 {
    padding: 0;
}

.social-media {
    display: flex;
}

.ccm-page .social-media-title {
    display: none;
}

.ccm-page .social-media-subtitle {
}

.social-media-items {
    display: flex;
    justify-content: center;
}

.social-media-items .social-media-link {
    display: flex;
    width: rem-calc(30px);
    align-items: center;
    justify-content: center;
    color: #fff;
}

.social-media-items .social-media-icon {
}

.social-media-items .social-media-link:hover {
    color: var(--link-hover-color);
}
