.download-list-wrapper {
    margin-top: 30px;
    margin-bottom: 30px;

    @include media-breakpoint-up(xl) {
        margin-bottom: 40px;
    }
}

.ccm-page .download-list-block-name {
    margin-bottom: 0;
}

.ccm-page .download-list-block-description {
    margin-top: rem-calc(15px);
}

.download-list {
    margin-top: rem-calc(20px);
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 15px 0;

    @include media-breakpoint-up(xxl) {
        margin-top: rem-calc(30px);
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 30px;
    }
}

.download-item {
    background: var(--background-1);
    border: 1px solid var(--border-1);
    color: var(--color-4);
    padding: 15px;
    border-radius: 10px;

    @include media-breakpoint-up(pm) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    @include media-breakpoint-up(md) {
        padding: 25px;
    }
}

.download-item-name {
    line-height: rem-calc(20px);
    margin-bottom: 15px;

    @include media-breakpoint-up(pm) {
        margin-bottom: 0;
    }
}

.download-item-button-container {
    @include media-breakpoint-up(pm) {
        margin-left: 20px;
    }
}

.download-item-button-container .btn {
    white-space: nowrap;
}

.download-item-button-container .btn .svg-inline--fa {
    margin-right: 5px;
    margin-left: -5px;
}
