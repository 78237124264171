// Level 1 variables
$nav-link-background: var(--nav-link-background);
$nav-link-color: var(--nav-link-color);
$triangle-color: $nav-link-color;
$icon-color: var(--nav-icon-color);

$nav-link-hover-background: var(--nav-link-hover-background);
$nav-link-hover-color: var(--nav-link-hover-color);
$triangle-hover-color: $nav-link-hover-color;
$icon-hover-color: var(--nav-icon-hover-color);

$nav-link-selected-background: var(--nav-link-selected-background);
$nav-link-selected-color: var(--nav-link-selected-color);

// Level 2+ variables
$show-submenu-on-desktop: 'true'; // Set to true for multilevel menu

$dropdown-link-background: var(--dropdown-link-background);
$dropdown-link-color: var(--dropdown-link-color);
$dropdown-triangle-color: var(--dropdown-triangle-color);

$dropdown-link-hover-background: var(--dropdown-link-hover-background);
$dropdown-link-hover-color: var(--dropdown-link-hover-color);
$dropdown-triangle-hover-color: var(--dropdown-triangle-hover-color);

@include media-breakpoint-up($top-view-switch) {
    // Common styles
    .desktop-nav .main-nav {
        display: flex;
        flex-direction: column;
    }

    .desktop-nav ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: block;
    }

    .desktop-nav li {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    // Level 1
    .desktop-nav .nav-level-1 {
        display: flex;
        flex-direction: column;
        flex: 1;
        position: relative;
    }

    .desktop-nav .nav-level-1 > li {
        display: flex;
        position: relative;
    }

    .desktop-nav .nav-level-1 > li:first-child > a {
        border-top: 1px solid var(--border-color-3);
    }

    .desktop-nav .nav-level-1 > li > a {
        display: flex;
        align-items: center;
        padding: 1.5vh 30px 1.5vh 39px;
        background: $nav-link-background;
        color: $nav-link-color;
        transition: 0.3s;
        position: relative;
        flex: 1;
        border-bottom: 1px solid var(--border-color-3);
        text-align: left;
        justify-content: flex-start;
        font-size: rem-calc(16px);
        line-height: 20px;
        font-weight: $font-weight-bold;

        @include min-screen-height(900px) {
            padding: 21px 30px 21px 39px;
        }
    }

    .desktop-nav .nav-level-1 > li:last-child > a {
        border-bottom: 0;
    }

    .desktop-nav .nav-level-1 > li:last-child > a {
        border-bottom: 0;
    }

    .desktop-nav .nav-level-1 > li > a .main-nav-icon {
        width: 50px;
        color: $icon-color;
        font-size: 30px;
    }

    .desktop-nav .nav-level-1 > li > a:hover .main-nav-icon {
        color: $icon-hover-color;
    }

    .desktop-nav .nav-level-1 > li > a:hover {
        background: $nav-link-hover-background;
        color: $nav-link-hover-color;
        text-decoration: none;
    }

    .ccm-page .desktop-nav .nav-level-1 > li > a.nav-path-selected {
        color: $nav-link-selected-color;
        background: $nav-link-selected-background;
    }

    // Hide hover/active state on nav-path-selected when hovering over navigation
    .ccm-page .desktop-nav:hover .nav-level-1 > li > a.nav-path-selected {
        background: $nav-link-background;
        color: $nav-link-color;
    }

    .ccm-page .desktop-nav:hover .nav-level-1 > li > a.nav-path-selected:hover {
        background: $nav-link-hover-background;
        color: $nav-link-hover-color;
    }

    .ccm-page
        .desktop-nav:hover
        .nav-level-1
        > li.nav-path-selected:not(:hover):after {
        transform: scale(0);
    }

    @if $show-submenu-on-desktop != 'true' {
        .desktop-nav .nav-level-1 > li > a.nav-path-selected:not(.nav-home) {
            background: $nav-link-hover-background;
            color: $nav-link-hover-color;
            text-decoration: none;
        }
    }

    @if $show-submenu-on-desktop == 'true' {
        // Dropdown common styles

        .desktop-nav li.has-dropdown {
            position: relative;
        }

        .desktop-nav .nav-dropdown {
            position: absolute;
            left: 100%;
            top: 0;
            right: auto;
            overflow: visible;
            //clip: rect(1px, 1px, 1px, 1px);
            width: auto;
            min-width: 100%;
            opacity: 0;
            pointer-events: none;
            transition: 0.3s;
            transform: translateX(50px);
        }

        // This class is added on lvl 3+
        .desktop-nav .nav-dropdown-horizontal {
            left: 100%;
            top: 0;
        }

        // Level 2+
        .desktop-nav .nav-dropdown li {
            float: left;
            width: 100%;
        }

        .desktop-nav .nav-dropdown > li:first-child {
            position: relative;
        }

        .desktop-nav .nav-dropdown > li:first-child a {
            border-radius: 0 10px 0 0;
        }

        .desktop-nav .nav-dropdown > li:last-child {
            position: relative;
        }

        .desktop-nav .nav-dropdown > li:last-child a {
            border-radius: 0 0 10px 0;
        }

        .desktop-nav .nav-dropdown > li:first-child:after {
            content: '';
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10px 10px 10px 0;
            border-color: transparent
                $dropdown-triangle-color
                transparent
                transparent;
            position: absolute;
            right: 100%;
            top: 0;
            bottom: 0;
            margin: auto;
            transition: 0.3s;
        }

        .desktop-nav .nav-dropdown > li:hover:first-child:after {
            border-color: transparent
                $dropdown-triangle-hover-color
                transparent
                transparent;
        }

        .desktop-nav .nav-dropdown > li.nav-path-selected:after {
            border-color: transparent
                $dropdown-triangle-hover-color
                transparent
                transparent;
        }

        .desktop-nav li.nav-path-selected li a.nav-selected {
            border-bottom-color: var(--border-color-4);
        }

        .desktop-nav .nav-dropdown li a {
            display: flex;
            align-items: center;
            padding: 20px 25px;
            white-space: nowrap;
            background: $dropdown-link-background;
            color: $dropdown-link-color;
            border-bottom: 1px solid var(--border-color-4);

            @include min-screen-height(1000px) {
                min-height: 83px;
            }
        }

        .desktop-nav .nav-dropdown li:last-child a {
            border-bottom: 0;
        }

        .desktop-nav .nav-dropdown li a:hover {
            color: $dropdown-link-hover-color;
            background: $dropdown-link-hover-background;
            text-decoration: none;
            border-bottom-color: var(--border-color-4);
        }

        // Nav reveal
        .desktop-nav li:hover > .nav-dropdown {
            clip: auto;
            opacity: 1;
            pointer-events: all;
            transform: translateX(0px);
        }

        // Has dropdown triangle
        .desktop-nav a.has-dropdown {
            position: relative;
        }

        // Level 1
        .desktop-nav .nav-level-1 > li > a.has-dropdown:after {
            display: none;
            content: ' ';
            margin-left: 10px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 4px 3px 0 3px;
            border-color: $triangle-color transparent transparent transparent;
            transition: 0.3s;
        }

        .desktop-nav .nav-level-1 > li > a.has-dropdown:hover:after {
            border-color: $triangle-hover-color
                transparent
                transparent
                transparent;
        }

        // Level 2+
        .desktop-nav .nav-dropdown > li > a.has-dropdown:after {
            content: ' ';
            margin-left: auto;
            width: 0;
            height: 0;
            border-style: solid;
            transition: 0.3s;
            border-width: 3px 0 3px 4px;
            border-color: transparent
                transparent
                transparent
                $dropdown-triangle-color;
        }

        .desktop-nav .nav-dropdown > li > a.has-dropdown:hover:after {
            border-color: transparent
                transparent
                transparent
                $dropdown-triangle-hover-color;
        }

        .desktop-nav .nav-dropdown > li > a.has-dropdown > span {
            margin-right: 10px;
        }

        // Nav path selected

        // Level 1
        .desktop-nav .nav-level-1 > li > a.nav-path-selected:not(.nav-home) {
            //background: $nav-link-hover-background;
            //color: $nav-link-hover-color;
            //text-decoration: none;
        }

        // Level 1 triangle
        .desktop-nav
            .nav-level-1
            > li
            > a.has-dropdown.nav-path-selected:after {
            border-color: $triangle-hover-color
                transparent
                transparent
                transparent;
        }

        // Level 2+
        .desktop-nav .nav-dropdown li > a.nav-path-selected {
            color: $dropdown-link-hover-color;
            background: $dropdown-link-hover-background;
            text-decoration: none;
            border-bottom-color: var(--border-color-4);
        }

        // Level 2+ triangle
        .desktop-nav
            .nav-dropdown
            > li
            > a.has-dropdown.nav-path-selected:after {
            border-color: transparent
                transparent
                transparent
                $dropdown-triangle-hover-color;
        }

        // Leave trace when hovering level-1
        .desktop-nav .nav-level-1 > li:hover > a {
            //background: $nav-link-hover-background;
            //color: $nav-link-hover-color;
            //text-decoration: none;
        }

        // Level 1 triangle
        .desktop-nav .nav-level-1 > li:hover > a:after {
            border-color: $triangle-hover-color
                transparent
                transparent
                transparent;
        }

        // Leave trace when hovering level 2+
        .desktop-nav .nav-dropdown li:hover > a {
            color: $dropdown-link-hover-color;
            background: $dropdown-link-hover-background;
            text-decoration: none;
        }

        .desktop-nav .nav-dropdown > li:hover > a:after {
            border-color: transparent
                transparent
                transparent
                $dropdown-triangle-hover-color;
        }
    } @else {
        .desktop-nav .has-dropdown .nav-dropdown {
            display: none !important;
        }
    }
}

// Hide hover/active state on nav-path-selected when hovering over navigation
.ccm-page .desktop-nav.nav-level-2:hover > li > a.nav-path-selected {
    color: $nav-link-color;
}

.ccm-page .desktop-nav .nav-level-2:hover > li > a.nav-path-selected:hover {
    color: $nav-link-hover-color;
}
