.ccm-page .page-list-no-pages,
.ccm-page .page-list-wrapper {
    margin-bottom: 40px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 50px;
    }
}

.page-list-no-pages {
    text-align: center;
}

.ccm-pagination-wrapper {
    margin-top: 30px;

    @include media-breakpoint-up(md) {
        margin-top: 50px;
        display: flex;
        justify-content: center;
    }
}

.ccm-page .page-list-heading {
}

.ccm-page .page-list {
}

.ccm-page .page-list-item {
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid var(--border-color);

    @include media-breakpoint-up(md) {
        margin-bottom: 30px;
        padding-bottom: 30px;
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: 40px;
        padding-bottom: 40px;
    }
}

.ccm-page .page-list-item:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: 0;
}

.ccm-page .page-list-item-title {
    margin-bottom: 16px;
}

.ccm-page .page-list-item-title a {
    color: inherit;
}

.ccm-page .page-list-item-title a:hover {
    color: var(--primary);
    text-decoration: none;
}

.ccm-page .page-list-item-date {
    margin-bottom: 10px;
    font-size: 0.8rem;
    color: $gray-500;
}

.ccm-page .page-list-item-description {
}

.page-list-item-read-more-container {
    margin-top: 15px;
}

.ccm-page .page-list-item-read-more {
}

.ccm-page .page-list-item-read-more:hover {
    text-decoration: none;
}

// With thumbnail
.page-list-item.with-thumbnail {
    @include media-breakpoint-up(sm) {
        display: grid;
        grid-template-columns: 150px 1fr;
        grid-template-rows: 1fr auto auto auto auto 1fr;
        grid-gap: 0 20px;
        grid-template-areas:
            'page-list-item-thumbnail .'
            'page-list-item-thumbnail page-list-item-title'
            'page-list-item-thumbnail page-list-item-date'
            'page-list-item-thumbnail page-list-item-description'
            'page-list-item-thumbnail page-list-item-read-more'
            'page-list-item-thumbnail .';
    }

    @include media-breakpoint-up(md) {
        grid-template-columns: 200px 1fr;
        grid-gap: 0 30px;
    }

    @include media-breakpoint-up(lg) {
        grid-template-columns: 200px 1fr;
        grid-gap: 0 40px;
    }
}

.with-thumbnail .page-list-item-thumbnail-container {
    margin-bottom: 15px;

    @include media-breakpoint-up(sm) {
        grid-area: page-list-item-thumbnail;
        margin-bottom: 0;
        margin-top: 5px;
    }
}

.with-thumbnail .page-list-item-thumbnail {
    align-self: flex-start;

    @include media-breakpoint-down(pl) {
        height: 200px;
        object-fit: cover;
        width: 100%;
    }
}

.page-list-item-title {
    margin-bottom: 20px;
    @include fluid(24px, 34px);
    @include fluid(28px, 38px, 'line-height');

    @include media-breakpoint-up(sm) {
        grid-area: page-list-item-title;
    }
}

.page-list-item-date {
    @include media-breakpoint-up(sm) {
        grid-area: page-list-item-date;
    }
}

.page-list-item-description {
    @include media-breakpoint-up(sm) {
        grid-area: page-list-item-description;
    }
}

.page-list-item-read-more-container {
    @include media-breakpoint-up(sm) {
        grid-area: page-list-item-read-more;
    }
}
