.logo-list-wrapper {
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;

    @include media-breakpoint-up(md) {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    @include media-breakpoint-up(xxl) {
        padding-top: 23px;
        padding-bottom: 18px;
    }
}

.ccm-page .logo-list-title {
    font-family: $font-family-sans-serif;
    font-size: rem-calc(20px);

    @include media-breakpoint-up(md) {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        flex: 0 0 auto;
        width: 200px;
    }
}

.logo-list-container {
    @include media-breakpoint-up(md) {
        display: flex;
    }
}

.logo-list {
    @include media-breakpoint-up(md) {
        min-width: 0;
        overflow: hidden;
    }
}

.logo-list-item {
    display: flex;
    height: 80px;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-up(md) {
        height: 99px;
    }
}

.logo-list-item-link {
    position: relative;
}

.logo-list-image-wrapper {
    display: block;
}

.logo-list-image-wrapper-2 {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
}

.logo-list-item .logo-list-image {
    margin: 0 auto;
    transition: 0.3s;
    max-width: 100%;
    height: auto;
    object-fit: contain;
    width: auto;
}

.logo-list-item a .logo-list-image-2 {
    opacity: 0;
}

.logo-list-item a:hover .logo-list-image-1 {
    opacity: 0;
}

.logo-list-item a:hover .logo-list-image-2 {
    opacity: 1;
}
