.toplayer{
    display: none;
	position: fixed;
	z-index: 10000;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	background-color: #224b31 !important;

	@include media-breakpoint-down(md) {
		max-width: 150vw;
		padding-left: 40px;
	}


    .toplayer-inner img{
		max-height: 100%;
		width: 100%;
		max-width: 100vw;
		height: 100%;
    }
}
