.slider-pagination-container {
    position: static;
    //bottom: 0;
    //left: 0;
    //right: 0;
    //z-index: 10;
    display: none;

    @include media-breakpoint-up(md) {
        display: flex;
        bottom: 10px;
    }

    @include media-breakpoint-up(lg) {
        bottom: 20px;
    }

    @include media-breakpoint-up(xxl) {
        bottom: 30px;
    }

    @include media-breakpoint-up(xxxl) {
        bottom: 44px;
    }
}

.slider-pagination {
    position: static;
    display: grid;
    grid-auto-flow: column;
    justify-content: $bullets-position;
    flex: 1;
    margin: 0 calc(#{$bullets-gap} * -1);
}

.slider-pagination .swiper-pagination-bullet {
    display: flex;
    cursor: pointer;
    opacity: 1;
    width: auto;
    height: auto;
    padding: $bullet-clickable-area-vertical 0;
    border-radius: 0;
    background: none;
}

.slider-pagination .swiper-pagination-bullet-inner {
    display: block;
    background: $bullet-background;
    width: var(--bullet-width, #{$bullet-width});
    height: $bullet-height;
    border-radius: $bullet-border-radius;
    border: $bullet-border-width solid $bullet-border-color;
    transition: 0.3s;
}

.slider-pagination
    .swiper-pagination-bullet-active
    .swiper-pagination-bullet-inner {
    width: var(--bullet-width-active, #{$bullet-width-active});
    background: var(--bullet-active-background);
    border-color: var(--bullet-active-border-color);
}
