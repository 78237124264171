.page-template-job-offer-list .job-offer-list-text-top-area {
}

.page-template-job-offer-list .job-offer-list-area {
    margin-top: rem-calc(30px);

    @include media-breakpoint-up(md) {
        margin-top: rem-calc(40px);
    }

    @include media-breakpoint-up(xl) {
        margin-top: rem-calc(60px);
    }

    @include media-breakpoint-up(xxl) {
        margin-top: rem-calc(70px);
    }
}

.page-template-job-offer-list .job-offer-list-form-area {
    margin-top: rem-calc(30px);

    @include media-breakpoint-up(md) {
        margin-top: rem-calc(40px);
    }

    @include media-breakpoint-up(xl) {
        margin-top: rem-calc(60px);
    }

    @include media-breakpoint-up(xxl) {
        margin-top: rem-calc(70px);
    }
}

.page-template-job-offer-list .job-offer-list-text {
}

.page-template-job-offer-list .job-offer-list-text h2 {
}
