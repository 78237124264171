@include media-breakpoint-up(md) {
    html {
        -ms-overflow-style: auto;
        scrollbar-color: var(--scrollbar-foreground) var(--scrollbar-background);
        scrollbar-width: thin;
    }

    @-moz-document url-prefix() {
        // Fix for firefox content jump when modal is opening (but page is scrollable when modal is open)
        // As an alternative use "scrollbar-gutter: stable both-edges;" instead.
        html {
            overflow-y: scroll;
            overflow-x: hidden;
        }
    }

    ::-webkit-scrollbar-thumb {
        background-color: var(--scrollbar-foreground);
    }

    ::-webkit-scrollbar-track {
        background-color: var(--scrollbar-background);
    }

    ::-webkit-scrollbar-corner {
        background-color: var(--scrollbar-background);
    }

    ::-webkit-scrollbar {
        width: var(--scrollbar-width);
        height: var(--scrollbar-height);
    }
}
