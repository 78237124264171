//
// Common
//
.styled-alerts .alert {
    border: 0;
    color: var(--body-color-2);
    display: grid;
    grid-template-columns: auto 1fr;
    padding: 0;
    text-align: left;
    overflow: hidden;
}

.styled-alerts .alert-icon {
    color: #fff;
    padding: 20px 10px;

    @include fluid(40px, 50px, $max-vw: lg);

    @include media-breakpoint-up(md) {
        padding: 30px;
    }
}

.styled-alerts .alert-text {
    color: var(--styled-alert-color);
    padding: 20px;

    @include media-breakpoint-up(md) {
        padding: 30px;
    }
}

.styled-alerts .alert-text h3 {
    font-size: 30px;
    margin-bottom: 10px;
}

.styled-alerts .alert-text ul {
    @extend %custom-ul-no-margin;
}

.styled-alerts .alert-text ul li {
    margin-bottom: 0;

    @extend %custom-ul-item;
}

.styled-alerts .alert-text ul:last-child,
.styled-alerts .alert-text p:last-child {
    margin-bottom: 0;
}

//
// Danger
//
.styled-alerts .alert-danger {
    background: lighten($danger, 35%);
}

.styled-alerts .alert-danger .alert-text {
    background: var(--styled-alert-danger-background);
}

.styled-alerts .alert-danger .alert-icon {
    background: $danger;
}

.styled-alerts .alert-danger .alert-text ul li:before {
    background: $danger;
}

//
// Success
//
.styled-alerts .alert-success {
    background: lighten($success, 50%);
}

.styled-alerts .alert-success .alert-text {
    background: var(--styled-alert-success-background);
}

.styled-alerts .alert-success .alert-icon {
    background: $success;
}

.styled-alerts .alert-success .alert-text ul li:before {
    background: $success;
}
