/**
* Want to change something that's not here?
* You can override every variable from `node_modules/bootstrap/scss/_variables.scss`.
* Docs: https://getbootstrap.com/docs/4.3/getting-started/theming/
*/

// Enable/disable settings
$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;
$enable-transitions: true;
$enable-responsive-font-sizes: false;

// ----------------------------------------------------------------------------
// Colors
// ----------------------------------------------------------------------------

$border-radius: 10px;

// Grays
$gray-100: #f4f4f4;
$gray-200: #eee;
$gray-300: #ddd;
$gray-400: #ccc;
$gray-500: #aaa;
$gray-600: #777777;
$gray-700: #444;
$gray-800: #333;
$gray-900: #222;

// Border-color
$border-color: #779865;
$border-color-2: #6b8f55;
$border-color-3: #e9e9e9;
$border-color-4: #bbd444;

// Theme colors
$primary: #3f632c;
$secondary: #9ebf06;
$tertiary: #4c882c;

$green-3: #598f3d;

$success: #6fbd80;
//$info: #92cd9e;
//$warning:       $yellow;
$danger: #f15f5f;
//
//$alert-bg-level: -3;
//$alert-border-level: -3;

//$component-active-bg: rgba($secondary, .25) !default;

// Body color
$body-bg: #496b37;
$body-bg-2: var(--body-bg-2);

// Text color
$body-color: #fff;
$body-color-2: #222222;

// Links
$link-color: var(--link-color); // #9ebb8e
$link-decoration: none;
$link-hover-color: var(--link-hover-color);
$link-hover-decoration: none;

// ----------------------------------------------------------------------------
// Font
// ----------------------------------------------------------------------------

//$font-weight-lighter:         100;
//$font-weight-light:           300;
$font-weight-normal: 400;
$font-weight-bold: 500;
//$font-weight-bolder:          600;

$font-weight-base: $font-weight-normal;

$font-size-base: 1rem;
$line-height-base: 1.5;
$font-family-sans-serif: 'Poppins', -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font-family-secondary: Cambria, 'Georgia', 'Hoefler Text', Utopia,
    'Liberation Serif', 'Nimbus Roman No9 L Regular', Times, 'Times New Roman',
    serif;

// Headings
//$headings-color: $primary;
$headings-font-family: $font-family-secondary;
$headings-font-weight: 400;
$headings-margin-bottom: rem-calc(20px);
$headings-margin-bottom-desktop: rem-calc(30px);

// Paragraphs
$paragraph-margin-bottom-mobile: rem-calc(25px);
$paragraph-margin-bottom-desktop: rem-calc(25px);
$lead-font-weight: 400;

// ----------------------------------------------------------------------------
// Other
// ----------------------------------------------------------------------------

// Spacing
$spacer: 2rem;

// Transition
$transition-base: all 0.3s;

// Layout
$grid-breakpoints: (
    xs: 0,
    ps: 360px,
    pm: 414px,
    pl: 480px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1440px,
    xxxl: 1860px
);

$grid-gutter-width: 30px;

$container-max-widths: (
    xxl: 1408px + $grid-gutter-width
);

// Forms
$form-group-margin-bottom: 10px;
$input-placeholder-color: rgba(#fff, 0.8);
$custom-file-button-bg: #66914f;
$custom-file-text: (
    pl: 'Wybierz',
    en: 'Choose'
);
$input-padding-y: rem-calc(10px);
$input-padding-x: rem-calc(20px);
$input-border-color: #fff;
$input-color: #fff;
$input-focus-width: 0;
$input-box-shadow: none;
$input-focus-box-shadow: none;
$input-focus-border-color: var(--secondary);
$input-border-width: 1px;
$input-height-border: $input-border-width * 2;
$input-bg: transparent;
$custom-control-indicator-size: rem-calc(20px);
$custom-control-gutter: rem-calc(14px);

// Custom indicator
//$custom-control-indicator-size: 1rem;
//$custom-control-indicator-bg: #fff;
//$custom-control-indicator-border-color: $border-color;
//$custom-control-indicator-border-width: 1px;
//$custom-control-indicator-box-shadow: none;
//
//$custom-control-indicator-active-color: $tertiary;
//$custom-control-indicator-active-bg: $tertiary;
//$custom-control-indicator-focus-border-color: $custom-control-indicator-bg;
//$custom-control-indicator-focus-border-color: $custom-control-indicator-bg;
//
$custom-control-indicator-checked-color: #fff;
$custom-control-indicator-checked-bg: $secondary;
$custom-control-indicator-checked-border-color: $secondary;

// Form floating labels
$form-floating-height: calc(3.5rem + #{$input-height-border});
$form-floating-line-height: 1.25;
$form-floating-padding-x: $input-padding-x;
$form-floating-padding-y: 1rem;
$form-floating-input-padding-t: 1.625rem;
$form-floating-input-padding-b: 0.625rem;
$form-floating-label-opacity: 0.65;
$form-floating-label-transform: scale(0.85) translateY(-0.5rem)
    translateX(0.15rem);
$form-floating-transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;

// Breadcrumbs
$breadcrumb-bg: $body-bg;
$breadcrumb-font-size: rem-calc(14px);
$breadcrumb-color: var(--body-color);
$breadcrumb-active-color: var(--body-color);
$breadcrumb-divider-color: var(--body-color);
$breadcrumb-item-padding: 0.5rem;
$breadcrumb-margin-bottom: 0;
$breadcrumb-padding-y: rem-calc(20px);
$breadcrumb-divider: quote('>');

// Buttons
$input-btn-padding-y: rem-calc(8px);
$input-btn-padding-x: rem-calc(25px);
//$btn-padding-y: .5rem;
//$btn-padding-x: 2rem;
//$btn-font-weight: $font-weight-bold;
$btn-border-width: 2px;
$btn-focus-width: 0;
$btn-focus-box-shadow: 0;
$btn-border-radius: 5px;

// Horizontal line
$hr-margin-y: 1.5rem;

// Tooltips
$tooltip-font-size: rem-calc(14px);
$tooltip-bg: var(--tooltip-bg);
$tooltip-color: var(--tooltip-color);
$tooltip-padding-y: rem-calc(8px);
$tooltip-padding-x: rem-calc(12px);
$tooltip-max-width: 250px;

// Tables
$table-cell-padding: 1rem;
$table-cell-padding-sm: 0.5rem;
$table-color: $body-color;
$table-bg: #5b8048;
$table-accent-bg: #71995c;
//$table-hover-color: #d9e5d3;
$table-hover-bg: transparent;
//$table-active-bg: $table-hover-bg;
$table-border-width: 0;
$table-border-color: var(--body-bg); //
$table-radius: 10px;

// hr
$hr-border-color: rgba(#fff, 0.3);
$hr-border-width: 2px;

// -----------------------------------------------------------------------------
// Custom variables
// -----------------------------------------------------------------------------
$silnet-logo-image-opacity: 0.5;

// Font awesome variable
$font-awesome: 'Font Awesome 5 Pro';
$font-awesome-kit: 'Font Awesome Kit';

// Text selection colors
$selection-bg: $primary !default;
$selection-color: #fff !default;

// mmenu
$mm_backgroundColor: var(--primary);
$mm_borderColor: rgba(#fff, 0.1);
$mm_dimmedTextColor: rgba(#fff, 0.8);
$mm_navbarColor: #fff;
$mm_textColor: #fff;
$mm_pagedimOpacity: var(--mm-page-dim-opacity);

$side-space: 50px !default;

$top-view-switch: lg !default;

$animation-breakpoint: md !default;
$animation-transition: 0.7s !default;
$transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275) !default;

$gallery-image-max-height: 80% !default;

// Slider pagination
$bullets-gap: 5px;
$bullets-position: start; // start | center | end

$bullet-width: 30px;
$bullet-height: 2px;
$bullet-clickable-area-vertical: 10px;
$bullet-border-radius: 0;
$bullet-border-width: 1px;

$bullet-background: var(--bullet-background);
$bullet-border-color: var(--bullet-border-color);

$bullet-width-active: 50px;
$bullet-background-active: var(--bullet-active-background);
$bullet-border-color-active: var(--bullet-border-color-active);

// Hamburger settings
$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-width: var(--hamburger-layer-width);
$hamburger-layer-height: 4px;
$hamburger-layer-spacing: 6px;
$hamburger-layer-color: var(--hamburger-color);
$hamburger-layer-border-radius: 4px;
$hamburger-hover-opacity: 0.7;
$hamburger-active-layer-color: $hamburger-layer-color;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter: false;
$hamburger-hover-filter: opacity(50%);
$hamburger-active-hover-filter: $hamburger-hover-filter;

// Types (Remove or comment out what you don’t need)
$hamburger-types: (squeeze);
