.history-area {
    margin-top: 30px;

    @include media-breakpoint-up(xl) {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 54px;
        position: relative;
        z-index: 40;
        align-items: start;
        margin-top: 50px;
    }

    @include media-breakpoint-up(xxl) {
        grid-gap: 94px;
    }

    @include media-breakpoint-up(xxxl) {
        grid-gap: 154px;
    }
}

.history-description {
    margin-bottom: 60px;
    position: relative;
    z-index: 2;

    @include media-breakpoint-up(xl) {
        grid-column: 2;
        grid-row: 1;
        margin-bottom: 0;
    }

    @include media-breakpoint-up(xxl) {
        margin-top: 52px;
    }
}

.history-description p {
    font-size: 16px;
    line-height: 20px;

    @include media-breakpoint-up(xxl) {
        width: 95%;
    }
}

.history-description p > .btn {
    @include media-breakpoint-up(xxxl) {
        margin-top: 23px;
    }
}

.history-description h1,
.history-description h2 {
    @include fluid(32px, 45px);
    @include fluid(36px, 49px, 'line-height');
}

.history-description h1::first-line,
.history-description h2::first-line {
    @include fluid(40px, 90px);
    @include fluid(45px, 100px, 'line-height');
}

.history-description h1:after,
.history-description h2:after {
    content: '';
    display: block;
    margin: 30px 0 27px;
    height: 2px;
    width: 50px;
    background: var(--stripe-color);

    @include media-breakpoint-up(xxxl) {
        margin-bottom: 42px;
    }
}

.history-cae {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr);
    position: relative;
    max-width: 546px;

    @include media-breakpoint-up(xl) {
        grid-column: 1;
        grid-row: 1;
    }

    @include media-breakpoint-up(xxxl) {
        margin-top: -90px;
    }
}

//
// Employees
//
.history-employees {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr);
    grid-column: 1;
    grid-row: 1;
    align-items: start;
}

.history-employee {
    position: relative;
    z-index: 20;
    overflow: hidden;
    max-width: 260px;
    border-radius: $border-radius;

    @include media-breakpoint-up(md) {
        //background: #cfcecc;
    }
}

.history-employee:nth-child(1) {
    grid-column: 1;
    grid-row: 1;
    width: 50%;
    padding-right: 5%;
    padding-bottom: 5%;

    @include media-breakpoint-up(xxxl) {
        padding: 0;
    }
}

.history-employee:nth-child(2) {
    grid-column: 1;
    grid-row: 1;
    width: 25%;
    margin-left: 75%;
}

.history-employee:nth-child(3) {
    grid-column: 1;
    grid-row: 1;
    width: 50%;
    margin-left: 50%;
    margin-top: 50%;

    @include media-breakpoint-up(lg) {
        padding-left: 5%;
        padding-top: 5%;
    }

    @include media-breakpoint-up(xxxl) {
        padding: 0;
        transform: translate(8px, 12px);
    }
}

.history-employee:nth-child(4) {
    grid-column: 1;
    grid-row: 1;
    margin-top: 80%;
    width: 25%;
    padding-right: 5%;
    padding-top: 5%;

    @include media-breakpoint-up(lg) {
        margin-top: 83%;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 89%;
        padding-right: 0;
        padding-top: 0;
        transform: translateY(50%);
    }

    @include media-breakpoint-up(xxl) {
        transform: translateY(0);
    }
}

.history-employee-inner {
    position: relative;
    height: 0;
    padding-bottom: 100%;
}

.history-employee img {
    border-radius: $border-radius;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    transition: 1.3s;
    opacity: 0;
}

.history-employee .active {
    opacity: 1;
}

.history-employee.placeholder {
    --placeholder-bg: transparent;
}

.history-employee-placeholder {
    background: var(--secondary);
    position: absolute;
    width: 50%;
    left: 33.33333%;
    top: 33.33333%;
    z-index: 0;

    @include media-breakpoint-up(lg) {
        left: 25%;
        top: 25%;
    }

    @include media-breakpoint-up(xl) {
        left: 24%;
        top: 21.4%;
        width: 52%;
        max-width: none;
        padding-bottom: 19px;
    }
}

//
// Counters
//
.history-counters {
    font-family: $font-family-secondary;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr);
    grid-column: 1;
    grid-row: 1;
    padding-top: 45%;
}

.history-counter-inner {
    display: flex;
    flex-direction: column;
    width: max-content;
    grid-column: 1;
    grid-row: 1;
    position: relative;
    z-index: 21;
}

.history-counter-number-bg {
    grid-column: 1;
    grid-row: 1;
    @include fluid(430px, 590px);
    @include fluid(130px, 160px, 'line-height');
    @include fluid(-40px, -60px, 'letter-spacing');
    color: #6b8f55;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #6b8f55;
    transform: translate(-6%, 0);
    position: relative;
    z-index: 1;

    @include media-breakpoint-up(sm) {
        right: -119px;
        //transform: translate(16%, 0);
    }

    @include media-breakpoint-up(md) {
        //transform: none;
        right: -119px;
    }

    @include media-breakpoint-up(lg) {
        left: -4%;
        top: -11%;
        //transform: translate(-10%, -11%);
    }

    @include media-breakpoint-up(xl) {
        right: auto;
        left: -17%;
        top: -12%;
        //transform: translate(-22%, -11%);
    }
}

.history-counter-prefix,
.history-counter-postfix {
    @include fluid(30px, 45px);
    @include fluid(30px, 38px, 'line-height');
}

.history-counter-number {
    @include fluid(140px, 300px);
    @include fluid(110px, 222px, 'line-height');
    @include fluid(-10px, -29px, 'letter-spacing');

    @include media-breakpoint-up(lg) {
        margin-left: -12px;
    }

    @include media-breakpoint-up(xxl) {
        margin-left: 0;
    }

    @include media-breakpoint-up(xxxl) {
        margin-left: 28px;
    }
}

.history-counter-prefix {
    @include media-breakpoint-up(xxl) {
        margin-left: 20px;
    }
    @include media-breakpoint-up(xxxl) {
        margin-left: 48px;
    }
}

.history-counter-postfix {
    text-align: right;
    padding-right: 18px;
    margin-top: 5px;

    @include media-breakpoint-up(lg) {
        margin-top: 14px;
        padding-right: 0;
    }
}
