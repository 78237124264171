.ccm-page .tabs-wrapper-offer {
    @include media-breakpoint-up(md) {
        display: grid;
        align-items: start;
        grid-template-columns: 350px 1fr;
        grid-gap: 50px;
    }

    @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    @include media-breakpoint-up(xl) {
        grid-template-columns: 400px 1fr;
    }

    @include media-breakpoint-up(xxl) {
        grid-template-columns: 486px 1fr;
    }

    @include media-breakpoint-up(xxxl) {
        grid-template-columns: 536px 1fr;
        margin-right: calc((var(--side-space) + 30px) * -1);
        grid-gap: 154px;
    }
}

.ccm-page .tabs-wrapper-offer .tab-content {
    @include media-breakpoint-down(sm) {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-gap: 10px;
    }
}

.ccm-page .tabs-wrapper-offer .accordion-title {
    @include media-breakpoint-down(sm) {
        padding: 0;
    }
}

.ccm-page .tabs-wrapper-offer .box-list-item-icon-container {
    @include media-breakpoint-down(sm) {
        height: auto;
        font-size: 30px;
        width: 45px;
    }

    @include media-breakpoint-up(md) {
        height: auto;
    }
}

.tabs-wrapper .accordion-header a {
    background: var(--body-bg);
    padding: 16px;
    color: var(--body-color);
    transition: 0.3s;
    border-radius: 10px;
    display: flex;
    position: relative;
    border: 1px solid var(--border-color);

    @include media-breakpoint-down(sm) {
        min-height: 62px;
    }
}

.tabs-wrapper-offer .accordion-header a[aria-expanded='true'] {
    background: var(--secondary);
    color: var(--color-1);
}

.tabs-wrapper-offer .accordion-header a[aria-expanded='true'] .nav-link-icon {
    color: #fff;
}

.tabs-wrapper-offer .accordion-header .nav-link-icon {
    transition: 0.3s;
}

.tabs-wrapper-offer .accordion-header .accordion-indicator {
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: block;
    color: inherit;
    transition: 0.2s;
    height: 16px;
}

/* prettier-ignore */
.tabs-wrapper-offer .accordion-header a[aria-expanded='true'] .accordion-indicator {
    transform: rotate(180deg);
}

.tabs-wrapper-offer .accordion-body-inner {
    border: 0;
    padding-left: 0;
    padding-right: 0;
}

.ccm-page .tab-offer-name {
    display: none;

    @include media-breakpoint-up(md) {
        display: block;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 35px;
    }

    @include media-breakpoint-up(xxl) {
        font-size: 60px;
        line-height: 60px;
        margin-top: 55px;
    }

    @include media-breakpoint-up(xxxl) {
        font-size: 90px;
        line-height: 90px;
        margin-top: 43px;
        margin-bottom: 53px;
    }
}

.ccm-page .tab-offer-name a:not(:hover) {
    color: var(--body-color);
}

.ccm-page .tabs-wrapper-offer .sub-offer-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.ccm-page .tabs-wrapper-offer .sub-offer-list-item {
    padding: 0;
    border-bottom: 1px solid var(--border-color);
    position: relative;
}

.ccm-page .tabs-wrapper-offer .sub-offer-list-item:last-child {
    border-bottom: 0;
}

.ccm-page .tabs-wrapper-offer .sub-offer-list-link {
    font-size: rem-calc(20px);
    line-height: 24px;
    font-family: $font-family-secondary;
    color: var(--body-color);
    display: flex;
    position: relative;
    padding: 10px 20px 10px 20px;

    @include media-breakpoint-up(md) {
        padding: 17px 0 17px 20px;
    }

    @include media-breakpoint-up(xl) {
        font-size: rem-calc(24px);
        line-height: 28px;
        padding-left: 30px;
    }

    @include media-breakpoint-up(xxxl) {
        font-size: rem-calc(30px);
        line-height: 34px;
        padding: 20px 0 20px 68px;
    }
}

.ccm-page .tabs-wrapper-offer .sub-offer-list-link:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 7px;
    top: 20px;
    width: 4px;
    height: 4px;
    background: var(--body-color);
    transition: 0.3s;

    @include media-breakpoint-up(md) {
        top: 27px;
    }

    @include media-breakpoint-up(xl) {
        left: 12px;
        top: 30px;
    }

    @include media-breakpoint-up(xxxl) {
        left: 43px;
        top: 37px;
    }
}

.ccm-page .tabs-wrapper-offer .sub-offer-list-link:hover {
    color: var(--secondary);
}

.ccm-page .tabs-wrapper-offer .sub-offer-list-link:hover:before {
    background: var(--secondary);
}

.tabs-wrapper-offer .sub-offer-indicator {
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 12px;
    height: 12px;
    bottom: 0;
    margin: auto;
    display: block;
    transform: rotate(-90deg);
}

html:not(.touch-device) .tabs-wrapper-offer .sub-offer-indicator {
    @include media-breakpoint-up(md) {
        display: none;
    }
}

html:not(.touch-device) .tabs-wrapper-offer .btn-sub-offer-more-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 44px;
    background: var(--body-bg);
    background: linear-gradient(
        to right,
        rgba(83, 203, 241, 0) 0%,
        #{$body-bg} 12%,
        #{$body-bg} 100%
    );
    padding-left: 50px;
    display: none;
    transition: 0.3s;
    opacity: 0;

    @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;
    }

    @include media-breakpoint-up(xxl) {
        height: 49px;
    }
}

.tabs-wrapper-offer .btn-sub-offer-more {
    padding: 8px 24px;
    font-family: $font-family-sans-serif;

    @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;
        align-self: center;
    }

    @include media-breakpoint-up(xxl) {
        padding: 10px 34px;
    }
}

html:not(.touch-device)
    .tabs-wrapper-offer
    .sub-offer-list-link:hover
    .btn-sub-offer-more-wrapper {
    @include media-breakpoint-up(md) {
        opacity: 1;
    }
}

.touch-device .tabs-wrapper-offer .btn-sub-offer-more {
    display: none;
}

.tabs-wrapper-offer .box-list {
    @include media-breakpoint-up(md) {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 10px;
    }

    @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @include media-breakpoint-up(xl) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 16px;
    }

    @include media-breakpoint-up(xxxl) {
        margin-top: -160px;
        position: relative;
        z-index: 10;
    }
}

.tabs-wrapper-offer .box-list .box-list-item-link {
    @include media-breakpoint-up(md) {
        align-items: flex-start;
        text-align: left;
        min-height: 170px;
        padding: 15% 10% 13% 10%;
        flex: 1;
        max-width: 100%;
    }

    @include media-breakpoint-up(xl) {
        padding: 15% 10% 13% 10%;
        min-height: 180px;
    }

    @include media-breakpoint-up(xxl) {
        min-height: 260px;
        padding: 16.2% 16.2% 17% 16.2%;
        justify-content: flex-start;
    }

    @include media-breakpoint-up(xxxl) {
        min-height: 260px;
        padding: 16.2% 18.2% 17% 18.2%;
        justify-content: flex-start;
    }
}

.tabs-wrapper-offer .box-list .box-list-item-inner {
    @include media-breakpoint-up(md) {
        flex: 1;
        display: flex;
        flex-direction: column;
    }
}

.tabs-wrapper-offer .box-list .box-list-item-title {
    @include media-breakpoint-up(md) {
        font-size: rem-calc(16px);
        line-height: 20px;
        margin-top: auto;
        align-items: flex-end;
    }

    @include media-breakpoint-up(xl) {
        font-size: rem-calc(18px);
        line-height: 22px;
    }

    @include media-breakpoint-up(xxl) {
        font-size: rem-calc(20px);
        line-height: 24px;
    }
}

//.tabs-wrapper-offer
//    .box-list
//    .box-list-item-link.active
//    .box-list-item-icon-container {
//    @include media-breakpoint-up(md) {
//        color: #fff;
//    }
//}

.ccm-page .tabs-wrapper-offer .box-list-item-icon.fa-money {
    font-size: 20px;

    @include media-breakpoint-up(md) {
        font-size: 40px;
    }

    @include media-breakpoint-up(xxl) {
        font-size: 44px;
        top: 4px;
    }
}

.ccm-page .tabs-wrapper-offer .box-list-item-icon.fa-pay {
    @include media-breakpoint-up(xxl) {
        font-size: 58px;
        top: -1px;
    }
}

.ccm-page .tabs-wrapper-offer .box-list-item-icon.fa-percent {
    @include media-breakpoint-between(pm, sm) {
        font-size: 24px;
    }
    @include media-breakpoint-up(xxl) {
        font-size: 51px;
        top: -1px;
    }
}

.ccm-page .tabs-wrapper-offer .box-list-item-icon.fa-smile {
    @include media-breakpoint-up(xxl) {
        font-size: 59px;
        top: -11px;
    }
}

.ccm-page .tabs-wrapper-offer .box-list-item-icon.fa-card {
    @include media-breakpoint-between(pm, sm) {
        font-size: 20px;
    }
    @include media-breakpoint-up(xxl) {
        font-size: 42px;
        top: 11px;
        left: -9px;
    }
}

.ccm-page .tabs-wrapper-offer .box-list-item-icon.fa-up-and-down {
    @include media-breakpoint-up(xxl) {
        font-size: 72px;
        top: -3px;
    }
}

.ccm-page .tabs-wrapper-offer .box-list-item-icon.fa-umbrella {
    @include media-breakpoint-up(xxl) {
        font-size: 57px;
        top: -3px;
    }
}

.ccm-page .tabs-wrapper-offer .box-list-item-icon.fa-pie-chart {
    @include media-breakpoint-up(xxl) {
        font-size: 61px;
        top: -3px;
    }
}
