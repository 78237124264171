$user-engagement-modal-radius: 20px;
.modal-user-engagement .modal-dialog {
    max-width: 700px;
    padding: 15px;
    margin: auto;

    @include media-breakpoint-up(xl) {
        max-width: 900px;
    }

    @include media-breakpoint-up(xxxl) {
        max-width: 1100px;
    }
}

.modal-user-engagement .modal-content {
    border-radius: $user-engagement-modal-radius;
    border: 0;
    color: var(--body-color-2);
    background: (--contrast-primary);
}

.modal-user-engagement .modal-body {
    padding: 0;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr);
}

// Text
.modal-user-engagement .modal-body .modal-text-wrapper {
    grid-column: 1;
    grid-row: 1;
    position: relative;
    z-index: 10;
    padding: 7% 7% 0 7%;
    align-self: start;
    border-radius: $user-engagement-modal-radius $user-engagement-modal-radius 0
        0;
    background: var(--contrast-primary);

    @include media-breakpoint-up(md) {
        padding: 5% 5% 0 5%;
    }

    @include media-breakpoint-up(xl) {
        padding-top: 4%;
    }
}

.modal-user-engagement .modal-body .modal-text-wrapper .close {
    background: var(--secondary);
    color: #fff;
    position: absolute;
    right: 0;
    top: 0;
    opacity: 1;
    display: block;
    padding: 10px;
    border-radius: 0 $user-engagement-modal-radius 0
        $user-engagement-modal-radius;
    text-shadow: none;

    @include media-breakpoint-up(lg) {
        border-radius: 10px;
        width: 44px;
        height: 44px;
        right: -56px;
        top: 0;
        font-size: 30px;
    }
}

.modal-user-engagement
    .modal-body
    .modal-text-wrapper
    .modal-text-button-wrapper {
    margin-bottom: 0;
}

.modal-user-engagement .modal-body .modal-text-wrapper .modal-title {
    @include fluid(26px, 34px);
    @include fluid(26px, 34px, 'line-height');
    margin-bottom: 20px;
    color: var(--link-color);
    font-weight: $font-weight-bold;

    @include media-breakpoint-up(xl) {
        font-size: 44px;
        line-height: 44px;
    }
}

.modal-user-engagement .modal-body .modal-text-wrapper .modal-title br {
}

.modal-user-engagement .modal-body .modal-text-wrapper .modal-description {
    font-family: $font-family-secondary;
    font-weight: $font-weight-bold;
    @include fluid(18px, 24px);
    @include fluid(22px, 28px, 'line-height');

    @include media-breakpoint-up(xl) {
        font-size: 26px;
        margin-bottom: 30px;
        margin-top: 30px;
    }
}

// Image
.modal-user-engagement .modal-body .modal-image-wrapper {
    grid-column: 1;
    grid-row: 2;
    position: relative;
    z-index: 5;
    margin-top: -10vw;

    @include media-breakpoint-up(md) {
        grid-row: 1;
        margin-top: 20px;
    }
}

.modal-user-engagement .modal-body .modal-image {
    border-radius: $user-engagement-modal-radius;
}

// Form
.modal-user-engagement .modal-form-wrapper {
    border-radius: $user-engagement-modal-radius;
    background: var(--body-bg-2);
    grid-column: 1;
    grid-row: 1 / 3;
    position: relative;
    z-index: 15;
    padding: 45px 7% 7%;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.3s;
    display: flex;
}

.modal-user-engagement .modal-form-wrapper.show {
    opacity: 1;
    pointer-events: auto;
}

[data-contrast='default']
    .modal-user-engagement
    .modal-form-wrapper
    .form-control {
    border-color: var(--border-color);
    color: var(--body-color-2);
}

[data-contrast='default']
    .modal-user-engagement
    .modal-form-wrapper
    .form-floating
    > label {
    color: #aaa;
}

.modal-user-engagement .modal-form-wrapper .close {
    position: absolute;
    left: 7%;
    top: 5px;

    @include media-breakpoint-up(pm) {
        top: 5%;
    }
}

.modal-user-engagement .modal-form-wrapper .close > span {
    display: flex;
    align-items: center;
}

.modal-user-engagement .modal-form-wrapper .close-arrow {
    font-size: 20px;
    margin-right: 14px;
}

.modal-user-engagement .modal-form-wrapper .close-label {
    font-size: 18px;
}

.modal-user-engagement .modal-form-wrapper .invalid-feedback {
    margin-left: 0;
}

.modal-user-engagement .modal-form-wrapper .form-agreement .invalid-feedback {
    font-size: 100%;
}

.modal-form-wrapper .modal-form-content > h3 {
    margin-bottom: 10px;
}

.modal-form-wrapper .modal-form-content-text > h3 {
    margin-bottom: 10px;
}

.modal-form-content-text {
    display: none;

    @include media-breakpoint-up(sm) {
        display: block;
    }
}
