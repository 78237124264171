// Area fixes
div.ccm-area {
    margin-bottom: 20px;
}

div.ccm-area-footer {
    padding-top: 0;
    top: 100%;
    margin-top: -1px;
}

div.ccm-area-footer div.ccm-area-footer-handle {
    height: auto;
    max-width: none;
    border-top: 1px solid #ccc;
}

div.ccm-area-footer div.ccm-area-footer-handle > span {
    white-space: nowrap;
}

.edit-mode div#ccm-menu-highlighter.ccm-block-highlight.ccm-global-area-block-highlight {
    outline: 0;
}
// ---

// Logged user side-nav fix
// Bootstrap uses display:flex
div.ccm-panel-content ul.nav,
div.ccm-panel-content menu {
    display: block;
}
// ---

// Custom modification - for "Customize Editing Interface" when one row template is set
body a.ccm-panel-add-block-draggable-block-type span {
    text-align: left;
    padding-left: 17px;
}

body a.ccm-panel-add-block-draggable-block-type span {
    width: 100%;
}

.ccm-panel-add-block-set a.ccm-panel-add-block-draggable-block-type span {
    width: 85%;
}

div#ccm-panel-add-block-stack-list>div.ccm-panel-add-clipboard-block-item>div.block-content>div.blocks,
div#ccm-panel-add-clipboard-block-list>div.ccm-panel-add-clipboard-block-item>div.block-content>div.blocks {
    overflow: hidden;
}
// ---

// Alternative template for select block/clipboard/stacks buttons when adding blocks etc.
body .ccm-panel-right div.ccm-panel-header-accordion nav li > a,
body .ccm-panel-right div.ccm-panel-header-accordion nav span {
    padding: 18px 15px 18px 40px;
}

body .ccm-panel-left div.ccm-panel-header-accordion nav ul.ccm-panel-header-accordion-dropdown {
    height: auto;
    display: flex;
}

body .ccm-panel-left div.ccm-panel-header-accordion nav span {
    display: none;
}

body .ccm-panel-left div.ccm-panel-header-accordion nav li {
    flex: 0 0 auto;
    width: 33.33333%;
    text-align: center;
}

body .ccm-panel-left div.ccm-panel-header-accordion nav ul.ccm-panel-header-accordion-dropdown:after {
    display: none;
}

body .ccm-panel-left div.ccm-panel-header-accordion nav li>a[data-panel-accordion-tab-selected] {
    background: #000000;
}
// ---

// Move background under toolbar when toolbar is visible
.toolbar-visible body,
.toolbar-visible .ccm-page {
    background-position-y: 48px;
}
// ---
