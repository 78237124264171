.contact-form .form-footer-text a {
    @extend %link-decoration-r;
}

.contact-form .form-footer-text a:after {
    bottom: -1px;
}

.contact-form .recaptcha,
.contact-form .form-agreement {
    color: inherit;
}

.contact-form .recaptcha > div {
    height: 0;
    overflow: hidden;
}

.contact-form .form-inputs {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

.contact-form .form-inputs .form-group {
    margin: 0;
}

.contact-form .form-group.custom-checkbox {
    margin-top: rem-calc(15px);
    margin-bottom: rem-calc(15px);
    font-size: rem-calc(12px);
    line-height: rem-calc(16px);
}

.contact-form .form-group.custom-checkbox .custom-control-label {
    padding-top: 5px;
}

.contact-form .form-footer-btn-container {
    margin-top: 20px;
}

.contact-form .was-validated .custom-select:valid,
.contact-form .was-validated .custom-select:invalid,
.contact-form .was-validated select.form-control:invalid,
.contact-form select.form-control.is-invalid {
    background-position: top 24px right 12px, top 16px right 29px;
}

.contact-form .form-inputs select.form-control {
    padding-right: 2.5rem;
}

.contact-form .was-validated select.form-control:valid,
.contact-form .was-validated select.form-control:invalid {
    padding-right: 3rem !important;
}

$contact-form-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'><path fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>");
$contact-form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>");
.contact-form .custom-select {
    background-image: escape-svg($contact-form-select-indicator);
}

.contact-form .custom-select.is-valid,
.contact-form .was-validated .custom-select:valid {
    background-image: escape-svg($contact-form-select-indicator), escape-svg($contact-form-feedback-icon-valid);
}
