.contact-info-footer {
}

.ccm-page .contact-info-footer-link {
    display: flex;
    align-items: center;
    color: var(--body-color);
}

.ccm-page .contact-info-footer-link:hover {
    color: var(--primary);
    text-decoration: none;
}

.contact-info-footer-icon {
    display: flex;
    cursor: pointer;
    height: 40px;
    width: 50px;
    align-items: center;
    justify-content: center;
    color: var(--primary);
    font-size: 20px;
    flex: 0 0 auto;

    @include media-breakpoint-up(xxl) {
        font-size: 24px;
    }
}

.contact-info-footer-icon svg {
    display: block;
    transition: 0.3s;
}
