.search-results-form {
}

.search-results-controls {
    display: flex;
}

.alert-search-results {
    margin-top: 30px;
    text-align: center;

    @include media-breakpoint-up(md) {
        margin-top: 50px;
    }
}

.search-results-controls .btn {
    margin-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    align-items: center;
}

.search-results {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 30px;
    margin-top: 30px;

    @include media-breakpoint-up(xl) {
        margin-top: 50px;
    }
}

.search-result {
    background: var(--background-1);
    color: var(--body-color-2);
    border-radius: 10px;
    padding: 15px;
    border: 1px solid var(--border-1);

    @include media-breakpoint-up(xl) {
        padding: 25px 30px;
    }
}

.search-result-link-wrapper a {
    color: #aaa;
}

.search-result-link-wrapper a:hover,
.search-result-link-wrapper a:focus {
    color: var(--secondary);
}

.search-result-link-wrapper .search-result-link {
    font-size: rem-calc(14px);
    line-height: rem-calc(16px);
    display: inline-block;
}

.ccm-page .search-result-name {
    font-size: rem-calc(26px);
    padding-top: 5px;
    margin-bottom: 15px;
    color: var(--color-3);
}

.ccm-page .search-result-name a:hover {
    color: var(--color-4);
}

.search-result-description {
    font-size: rem-calc(14px);
    //color: #4d5156;
    line-height: 1.58;
    color: var(--color-3);
}

.search-result-description span {
    background: rgba(#fff, 0.2) !important;
}
