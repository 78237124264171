[data-contrast='light']:root {
    --contrast-primary: #fff;
    --contrast-secondary: #000;
    --contrast-highlight: blue;

    --primary: var(--contrast-highlight);
    --secondary: var(--contrast-highlight);

    --body-color: var(--contrast-secondary);
    --body-color-2: var(--contrast-secondary);

    --body-bg: var(--contrast-primary);
    --body-bg-2: var(--contrast-primary);

    --border-color: var(--contrast-secondary);
    --border-color-2: var(--contrast-secondary);
    --border-color-3: var(--contrast-secondary);
    --border-color-4: var(--contrast-secondary);

    --background-1: var(--contrast-primary);
    --background-2: var(--contrast-highlight);
    --background-3: var(--contrast-secondary);

    --color-1: var(--contrast-primary);
    --color-2: var(--contrast-highlight);
    --color-3: var(--contrast-highlight);
    --color-4: var(--contrast-secondary);

    --border-1: var(--contrast-secondary);

    --hamburger-color: var(--contrast-secondary);
    --mm-color-background: var(--contrast-highlight);

    --link-color: var(--contrast-highlight);
    --link-hover-color: var(--contrast-highlight);
    --link-color-2: var(--contrast-secondary);
    --link-color-3: var(--contrast-secondary);

    --link-decoration-color: var(--contrast-highlight);
    --link-decoration-color-hover: var(--contrast-highlight);

    --nav-link-background: var(--contrast-primary);
    --nav-link-color: var(--contrast-highlight);
    --nav-link-hover-background: var(--contrast-highlight);
    --nav-link-hover-color: var(--contrast-primary);
    --nav-icon-color: var(--contrast-highlight);
    --nav-icon-hover-color: var(--contrast-primary);
    --nav-link-selected-background: var(--contrast-highlight);
    --nav-link-selected-color: var(--contrast-primary);
    --dropdown-link-background: var(--contrast-primary);
    --dropdown-link-color: var(--contrast-highlight);
    --dropdown-triangle-color: var(--contrast-primary);
    --dropdown-link-hover-background: var(--contrast-highlight);
    --dropdown-link-hover-color: var(--contrast-primary);
    --dropdown-triangle-hover-color: var(--contrast-highlight);

    --tooltip-bg: var(--contrast-highlight);
    --tooltip-color: var(--contrast-primary);

    --bullet-background: var(--contrast-secondary);
    --bullet-border-color: var(--contrast-secondary);
    --bullet-active-background: var(--contrast-highlight);
    --bullet-active-border-color: var(--contrast-highlight);

    --stripe-color: var(--contrast-highlight);

    --hr-bg: rgba(0, 0, 0, 0.2);
}

[data-contrast='light'] .main-nav .mm-listview [aria-hidden='true'],
[data-contrast='light'] .ccm-page [aria-hidden='true'] {
    display: none;
}

[data-contrast='light'] .site-logo {
    //
}

[data-contrast='light'] .ccm-page .custom-ul li {
    --secondary: var(--contrast-highlight);
}

[data-contrast='light'] .header-top-container-inner {
    @include media-breakpoint-up(lg) {
        border: 1px solid var(--border-color);
    }
}

[data-contrast='light'] .header-login-area {
    --secondary: transparent;
    border: 1px solid var(--border-color);
}

[data-contrast='light'] .desktop-nav .nav-dropdown {
    @include media-breakpoint-up(lg) {
        border-radius: $border-radius;
        border: 1px solid var(--contrast-secondary);
    }
}

[data-contrast='light'] .desktop-nav .nav-dropdown > li:after {
    display: none;
}

[data-contrast='light'] .desktop-nav .nav-dropdown > li:first-child > a {
    border-top-left-radius: $border-radius;
}

[data-contrast='light'] .desktop-nav .nav-dropdown > li:last-child > a {
    border-bottom-left-radius: $border-radius;
}

/* prettier-ignore */
[data-contrast='light'] .desktop-nav:not(:hover) .nav-level-1 a.nav-path-selected .main-nav-icon {
    color: var(--contrast-primary);
}

[data-contrast='light'] .page-top-content {
    @include media-breakpoint-only(xl) {
        width: 100%;
    }
}

[data-contrast='light'] .page-top-image-container {
    @include media-breakpoint-down(md) {
        display: none;
    }
    @include media-breakpoint-only(lg) {
        visibility: hidden;
    }
}

[data-contrast='light'] .ccm-page .page-top-image {
    visibility: hidden;
    transition: none;

    @include media-breakpoint-up(xxl) {
        visibility: visible;
    }
}

//[data-contrast='light'] .slider-pagination-container {
//    display: block;
//}

[data-contrast='light']
    .slider-pagination-container
    .swiper-pagination-bullets.swiper-pagination-horizontal {
    @include media-breakpoint-down(sm) {
        margin-left: 10px;
        width: auto;
    }
}

[data-contrast='dark'] .swiper-pagination-bullet-inner {
    //
    //
}

[data-contrast='light'] .swiper-slide .swiper-slide-small-text {
    background: var(--contrast-primary);
    color: var(--contrast-secondary);
    border: 0;
    padding: 10px;
}

[data-contrast='light'] .slider-home .swiper-slide-captions:before {
    background: linear-gradient(to right, #fff 0%, rgba(#fff, 0) 100%);

    @include media-breakpoint-up(md) {
        background: linear-gradient(to right, #fff 0%, rgba(#fff, 0.5) 100%);
    }
}

[data-contrast='light'] .page-top:before {
    background: var(--contrast-primary);

    @include media-breakpoint-down(xl) {
        display: none;
    }
}

[data-contrast='light'] .counter-title,
[data-contrast='light'] .counter-icon-container {
    color: var(--contrast-secondary);
}

[data-contrast='light'] .news-home-area {
    border-top: 1px solid var(--contrast-secondary);
    border-bottom: 1px solid var(--contrast-secondary);
}

[data-contrast='light'] .btn-primary,
[data-contrast='light'] .btn-secondary,
[data-contrast='light'] .btn-tertiary,
[data-contrast='light'] .btn-quaternary,
[data-contrast='light'] .btn-quinary {
    @include button-variant(blue, blue, #fff, blue, #fff, blue);
}

[data-contrast='light'] .modal-content {
    background: var(--contrast-primary);
    border: 1px solid var(--contrast-secondary);
}

[data-contrast='light'] .contact-info-top .contact-info-top-icon {
    border: 1px solid var(--contrast-primary);
    background: var(--contrast-highlight);
    color: var(--contrast-primary);
}

/* prettier-ignore */
[data-contrast='light'] .contact-info-top-item[data-open='1'] .contact-info-top-icon,
[data-contrast='light'] .ccm-page .contact-info-top-item:hover .contact-info-top-icon,
[data-contrast='light'] .ccm-page .contact-info-top-icon:hover {
    background: var(--contrast-highlight);
    color: var(--contrast-primary);
}

/* prettier-ignore */
[data-contrast='light'] .ccm-page .contact-info-top .contact-info-top-link,
[data-contrast='light'] .ccm-page .contact-info-top .contact-info-top-link:hover {
    border: 1px solid var(--contrast-primary);
    border-left: 0;
    overflow: hidden;
    color: var(--contrast-secondary);
}

/* prettier-ignore */
[data-contrast='light'] .contact-info-top-item:nth-child(2) .contact-info-top-icon {
    background: var(--contrast-highlight);
    color: var(--contrast-primary);
}

[data-contrast='light'] .close {
    color: var(--contrast-highlight);
}

[data-contrast='light'] .ccm-page .back-to-top {
    background: var(--contrast-highlight);
    color: var(--contrast-primary);
}

[data-contrast='light'] .mm-menu-search-form .form-control::placeholder {
    color: var(--contrast-secondary);
}

[data-contrast='light'] .mm-menu-search-form .form-control,
[data-contrast='light'] .mm-menu-search-form .form-control:focus {
    border-color: var(--contrast-secondary);
    background: var(--contrast-primary);
    color: var(--contrast-secondary);
}

[data-contrast='light'] .mm-menu-search-form .btn {
    background: var(--contrast-highlight);
    border-color: var(--contrast-highlight);
}

[data-contrast='light'] .mm-menu-search-form .btn:hover,
[data-contrast='light'] .mm-menu-search-form .btn:focus,
[data-contrast='light'] .mm-menu-search-form .btn:active {
    color: var(--contrast-primary) !important;
    background: var(--contrast-secondary) !important;
    border-color: var(--contrast-secondary) !important;
}

[data-contrast='light'] .mm-menu-search-form .btn svg {
    fill: var(--contrast-primary);
}

[data-contrast='light'] .header-login-area .btn:not(:hover) {
    background: var(--contrast-highlight);
    border-color: var(--contrast-highlight);
    color: var(--contrast-primary);
}

[data-contrast='light'] .slider-logo:hover img,
[data-contrast='light'] .slider-logo img {
    filter: hue-rotate(146deg) saturate(39);
    opacity: 1;
}

[data-contrast='light'] .currency-home-wrapper {
    border: 1px solid var(--contrast-secondary);
    border-radius: $border-radius;
}

[data-contrast='light'] .currency-home-image {
    display: none;
}

[data-contrast='light'] .page-list-item-date {
    color: var(--contrast-secondary);
}

[data-contrast='light'] .map-wrapper .leaflet-overlay-pane img {
    filter: invert(1);
}

[data-contrast='light'] .leaflet-popup-inner .leaflet-popup-inner-centrum svg {
    fill: var(--contrast-highlight);
}

/* prettier-ignore */
[data-contrast='light'] .history-employee .history-employee-inner {
    border-radius: 5px;
    overflow: hidden;
}

[data-contrast='light'] .home-calculator-wrapper {
    border: 1px solid var(--contrast-primary);
    filter: invert(1);
    border-radius: $border-radius;
}

[data-contrast='light'] .ccm-page .box-list-item-link.active,
[data-contrast='light'] .ccm-page .box-list-item-link:hover {
    --secondary: var(--contrast-highlight);
}

[data-contrast='light'] .box-list-item-icon-container {
    --secondary: var(--contrast-secondary);
}

[data-contrast='light'] .breadcrumbs-container {
    @include media-breakpoint-down(lg) {
        background: var(--contrast-primary);
    }
}

/* prettier-ignore */
[data-contrast='light'] .breadcrumbs-container .breadcrumbs-nav .overlay-gradient.left {
    background: linear-gradient(to right, #fff 5%, fade-out(#fff, 1) 100%);
}

/* prettier-ignore */
[data-contrast='light'] .breadcrumbs-container .breadcrumbs-nav .overlay-gradient.right {
    background: linear-gradient(to right, fade-out(#fff, 1) 5%, #fff 100%);
}

/* prettier-ignore */
html[data-contrast='light']:not(.touch-device) .tabs-wrapper-offer .btn-sub-offer-more-wrapper {
    background: linear-gradient(
            to right,
            rgba(83, 203, 241, 0) 0%,
            #fff 12%,
            #fff 100%
    );
}

[data-contrast='light'] .table,
[data-contrast='light'] .table-striped tbody tr:nth-of-type(odd),
[data-contrast='light'] .table-striped tbody tr:nth-of-type(odd):hover {
    background: var(--contrast-primary);
}

[data-contrast='light'] .ccm-page .table th,
[data-contrast='light'] .ccm-page .table td {
    color: var(--contrast-secondary);
    border: 1px solid var(--contrast-secondary);
}

[data-contrast='light'] .ccm-page .form-control,
[data-contrast='light'] .mm-menu .form-control {
    border-color: var(--contrast-secondary);
    color: var(--contrast-secondary);
}

[data-contrast='light'] .ccm-page .form-control:focus,
[data-contrast='light'] .mm-menu .form-control:focus {
    border-color: var(--contrast-highlight);
    color: var(--contrast-secondary);
}

[data-contrast='light'] .form-floating > label {
    color: var(--contrast-secondary);
    opacity: 1 !important;
}

[data-contrast='light'] .contact-data {
    background: var(--contrast-primary);
    border: 1px solid var(--contrast-secondary);
}

[data-contrast='light'] .ccm-page .with-stripe:after {
    --secondary: var(--contrast-highlight);
}

[data-contrast='light'] .faq-intro.is-open,
[data-contrast='light'] .faq-intro:hover {
    color: var(--contrast-primary);
    background: var(--contrast-highlight);
}

[data-contrast='light'] .custom-file-label:after {
    background: var(--contrast-primary);
}

$svgIndicatorContrast: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='13.162' height='10.003' viewBox='0 0 13.162 10.003'><path id='icon_check' d='M383.682,715.017,382.2,713.54l-7.05,7.05L372,717.434l-1.478,1.477,4.634,4.632Z' transform='translate(-370.52 -713.54)' fill='white'/></svg>");

[data-contrast='light']
    .custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::after {
    background-image: escape-svg($svgIndicatorContrast);
}

[data-contrast='light']
    .custom-control-input:checked
    ~ .custom-control-label::before {
    background: var(--contrast-highlight);
    border-color: var(--contrast-highlight);
}
