.image-with-text{
    margin: 2rem 0;
    clear: both;
    & h2{
        font-size: 2rem;
    }
    & &__content{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        @include media-breakpoint-up(md){
            flex-direction: row;
        }
    }
    & &__item{
        display: flex;
        flex-direction: column;
        background-color: white;
        border-radius: 10px;
        overflow: hidden;
        font-size: 1.25rem;
        color: $primary;
        & p{
            margin: 0;
            padding: 1rem;
        }
        @include media-breakpoint-up(sm){
            flex-direction: row;
            align-items: center;
        }
        @include media-breakpoint-up(md){
            flex: 1;
            flex-direction: column;
            align-items:normal;
        }
    }
    & &__text{
        display: grid;
        place-content: center;
        text-align: center;
        width: 100%;
        height: 100%;
    }
    & &__image{
        @include media-breakpoint-up(sm){
            flex: 0 0 250px;
        }
        & img{
            width: 100%;
            max-width: 100%;
            height: auto;
            @include media-breakpoint-up(lg){
                max-height: 300px;
                object-fit: cover;
            }
        }
    }
}