.breadcrumbs-container {
    overflow: hidden;
    opacity: 0;
    padding: 0;
    margin-bottom: $breadcrumb-margin-bottom;
    background-color: $breadcrumb-bg;
    margin-left: calc(var(--side-space) * -1);
    margin-right: calc(var(--side-space) * -1);

    font-size: $breadcrumb-font-size;
    @include border-radius($breadcrumb-border-radius);
    @include basic-transition;

    @include media-breakpoint-up(lg) {
        position: relative;
        padding: 0;
        // left: -18px;
        // width: calc(100% + 18px);
        margin-left: 0;
        margin-right: 0;
        background: none;
        font-size: rem-calc(16px);
    }

    &.visible {
        opacity: 1;
    }

    .scroll-trigger {
        display: none;
    }

    &.scrollable {
        .scroll-trigger {
            @include media-breakpoint-up(lg) {
                display: flex;
                align-items: center;
                position: absolute;
                top: 1px;
                height: 100%;
                z-index: 2;
                font-size: rem-calc(18px);
                padding: 0 2px;
                cursor: pointer;
                opacity: 0;
                color: var(--primary);

                @include basic-transition;

                &.left {
                    left: $spacer / 4;
                }

                &.right {
                    right: $spacer / 4;
                }
            }
        }
    }

    &:hover {
        .scroll-trigger {
            @include media-breakpoint-up(lg) {
                opacity: 0.5;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }

    &.right {
        .scroll-trigger.right {
            opacity: 0 !important;
            visibility: hidden;
        }
    }

    &.left {
        .scroll-trigger.left {
            opacity: 0 !important;
            visibility: hidden;
        }
    }

    .breadcrumbs-nav {
        margin: 0;
        // font-size: 13px;
        position: relative;

        @include media-breakpoint-up(lg) {
            overflow: hidden;
            // margin-right: 30px;
        }

        .overlay-gradient {
            position: absolute;
            top: 0;
            height: 100%;
            width: 45px;
            z-index: 1;

            @include media-breakpoint-up(lg) {
                width: 35px;
            }

            &.left {
                left: -45px;
                background: linear-gradient(
                    to right,
                    $breadcrumb-bg 5%,
                    fade-out($breadcrumb-bg, 1) 100%
                );

                @include media-breakpoint-up(lg) {
                    left: -35px;
                }
            }

            &.right {
                right: -45px;
                background: linear-gradient(
                    to left,
                    $breadcrumb-bg 5%,
                    fade-out($breadcrumb-bg, 1) 100%
                );

                @include media-breakpoint-up(lg) {
                    right: -35px;
                }
            }
        }

        .breadcrumbs {
            display: flex;
            overflow: scroll;
            width: 100%;
            position: relative;
            padding: $breadcrumb-padding-y 0;
            margin: 0;
            white-space: nowrap;
            // left: -15px;
            // width: calc(100% + 15px);
            // padding-left: 15px;

            @include media-breakpoint-up(lg) {
                overflow: hidden;
                // left: 0;
                // width: 100%;
                // padding-left: 0;
            }

            .breadcrumbs-item {
                display: inline-flex;
                white-space: nowrap;
                padding-right: $breadcrumb-item-padding;

                &.active {
                    color: $breadcrumb-active-color;
                }

                &:after {
                    content: escape-svg($breadcrumb-divider);
                    display: inline-block; // Suppress underlining of the separator in modern browsers
                    padding-left: $breadcrumb-item-padding;
                    color: $breadcrumb-divider-color;
                }

                &:last-child:after {
                    display: none;
                }

                &:first-child {
                    padding-left: 15px;

                    @include media-breakpoint-up(lg) {
                        padding-left: 0;
                    }
                }

                &:last-child {
                    padding-right: 15px;

                    @include media-breakpoint-up(lg) {
                        padding-right: 0;
                    }
                }

                a {
                    color: $breadcrumb-color;

                    &:hover {
                        text-decoration: none;
                        color: var(--secondary);
                    }
                }
            }
        }
    }
}
