html[data-font-size='default'] {
    --font-size: 16px;
}

html[data-font-size='medium'] {
    --font-size: 18px;
}

html[data-font-size='large'] {
    --font-size: 20px;
}

html[data-font-size='default']
    .header-options-font
    button
    span:nth-child(1):after {
    opacity: 1;
}

html[data-font-size='medium']
    .header-options-font
    button
    span:nth-child(2):after {
    opacity: 1;
}

html[data-font-size='large']
    .header-options-font
    button
    span:nth-child(3):after {
    opacity: 1;
}

html {
    font-size: var(--font-size);
}

//html[data-font-size='large'] .slider-home .slider-pagination-container {
//}
