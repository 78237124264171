a[href^='tel'] {
    color: inherit;
    border: 0;
    text-decoration: none;
}

.show-number {
    font-size: 0.75em;
    line-height: 1;
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.24);
    border-radius: 3px;
    padding: 0 4px 1px;
    position: relative;
    top: -1px;
    text-decoration: none;

    @include basic-transition();

    &:hover {
        border: 1px solid rgba(0, 0, 0, 0.3);
        background: rgba(0, 0, 0, 0.2);
    }
}
