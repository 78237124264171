.contact-info-top {
    display: flex;
    border-radius: 5px;
    overflow: hidden;

    @include media-breakpoint-up($top-view-switch) {
        flex-direction: column;
    }

    @include media-breakpoint-up(xl) {
        border-radius: 0;
    }

    @include media-breakpoint-up(xxxl) {
        flex-direction: row;
    }
}

.contact-info-top-item {
    box-shadow: 0 3px 6px rgba(#000, 0.16);

    @include media-breakpoint-up(md) {
        display: flex;
    }
    @include media-breakpoint-up($top-view-switch) {
        margin-right: 0;
        margin-left: auto;
    }
}

.contact-info-top-item:last-child {
    @include media-breakpoint-up(md) {
        margin-right: 0;
    }
}

.contact-info-top-icon-label {
    display: block;
    font-size: 9px;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    //position: absolute;
    //left: 0;
    //right: 0;
    //text-align: center;
    //bottom: 2px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 2px;
    padding-bottom: 3px;

    @include media-breakpoint-up(md) {
        margin-top: 6px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 10px;
    }
}

.contact-info-top-icon {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    cursor: pointer;
    transition: 0.3s;
    width: auto;
    height: var(--header-item-height);
    align-items: center;
    //justify-content: center;
    font-size: 22px;
    background: var(--secondary);
    color: var(--color-1);

    @include media-breakpoint-up(pm) {
        width: var(--header-item-width);
    }

    @include media-breakpoint-up(md) {
        font-size: 26px;
    }
}

.contact-info-top-item:nth-child(1) .contact-info-top-icon {
    @include media-breakpoint-up(lg) {
        border-radius: 5px 5px 0 0;
    }
    @include media-breakpoint-up(xxxl) {
        border-radius: 5px 0 0 5px;
    }
}

.contact-info-top-item:nth-child(2) .contact-info-top-icon {
    background: var(--background-2);
    color: var(--primary);
}

.contact-info-top-item:nth-child(3) .contact-info-top-icon {
    background: var(--tertiary);
    color: var(--color-1);

    @include media-breakpoint-up(lg) {
        border-radius: 0 0 5px 5px;
    }

    @include media-breakpoint-up(xxxl) {
        border-radius: 0 5px 5px 0;
    }
}

.contact-info-top-icon .fa-email {
    font-size: 14px;
    position: relative;
    top: -2px;

    @include media-breakpoint-up(md) {
        font-size: 18px;
    }
}

.contact-info-top-icon .fa-lock {
    font-size: 20px;

    @include media-breakpoint-up(md) {
        font-size: 25px;
    }
}

.contact-info-top-icon .fa-phone-2 {
    @include media-breakpoint-up(md) {
        position: relative;
        top: 2px;
    }
}

.contact-info-top-icon:hover {
    background: var(--secondary);
    color: #fff;

    @include media-breakpoint-up($top-view-switch) {
        background: var(--primary);
        color: #fff;
    }
}

.contact-info-top .contact-info-top-text {
    white-space: nowrap;

    @include media-breakpoint-down(sm) {
        display: none !important;
    }

    @include media-breakpoint-up(md) {
        display: flex;
    }
}

//
// link
//
.ccm-page .contact-info-top-link[href^='tel:'],
.ccm-page .contact-info-top-link[href^='mailto:'] {
    color: var(--body-color-2);

    @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;
        padding: 0 15px;
        background: $gray-100;
    }
}

.ccm-page .contact-info-top-link[href^='tel:']:hover,
.ccm-page .contact-info-top-link[href^='mailto:']:hover {
    text-decoration: none;
    color: var(--primary);
}

// hover
.ccm-page .contact-info-top-item:hover .contact-info-top-link[href^='tel:'],
.ccm-page .contact-info-top-item:hover .contact-info-top-link[href^='mailto:'] {
    // .ccm-page .contact-info-top-item:hover .contact-info-top-icon
    text-decoration: none;
    color: var(--primary);
}

.ccm-page .contact-info-top-item:hover .contact-info-top-icon {
    @include media-breakpoint-up($top-view-switch) {
        background: var(--primary);
        color: #fff;
    }
}

.contact-info-top-item[data-open='1'] .contact-info-top-icon {
    @include media-breakpoint-up($top-view-switch) {
        background: var(--primary);
        color: #fff;
    }
}

.contact-info-top-item:nth-child(2)[data-open='1'] .contact-info-top-icon {
    @include media-breakpoint-up(xl) {
        border-radius: 5px 0 0 5px;
    }

    @include media-breakpoint-up(xxxl) {
        border-radius: 0;
    }
}

.contact-info-top-item:nth-child(3)[data-open='1'] .contact-info-top-icon {
    @include media-breakpoint-up(xl) {
        border-radius: 5px 0 0 5px;
    }

    @include media-breakpoint-up(xxxl) {
        border-radius: 0;
    }
}

.contact-info-top-item:nth-child(3) .contact-info-top-link {
    @include media-breakpoint-up(xxxl) {
        border-radius: 0 5px 5px 0;
    }
}
