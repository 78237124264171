.page-type-job-offer .page-title {
    @include fluid(34px, 50px, $force-px: true);
    @include fluid(34px, 50px, 'line-height', $force-px: true);
}

.job-offer {
}

.job-offer-description {
    margin-bottom: rem-calc(30px);
}

.job-offer-location {
    margin-top: rem-calc(30px);
    margin-bottom: rem-calc(30px);

    @include media-breakpoint-up(md) {
        margin-top: rem-calc(40px);
        margin-bottom: rem-calc(40px);
    }
}

// Form
.job-offer .job-offer-form-area {
    margin-top: rem-calc(30px);
    margin-bottom: rem-calc(40px);

    @include media-breakpoint-up(md) {
        margin-top: rem-calc(50px);
        margin-bottom: rem-calc(50px);
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: rem-calc(70px);
    }
}

.job-offer .job-offer-form-area h2 {
    @extend %heading-lead;
}

.job-offer-more-info {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 10px;

    @include media-breakpoint-up(md) {
        display: flex;
        grid-gap: 0;
    }
}

.job-offer-more-info .btn {
    width: 100%;

    @include media-breakpoint-up(md) {
        width: auto;
        margin-right: 10px;
    }
}

.page-type-job-offer .breadcrumbs .breadcrumbs-item.active,
.page-type-job-offer .breadcrumbs .breadcrumbs-item:nth-last-child(2):after,
.page-type-job-offer .breadcrumbs .breadcrumbs-item:nth-last-child(2) svg {
    display: none;
}
