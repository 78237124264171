//
// Layout
//
.tabs-wrapper .nav-pills {
    display: none;

    @include media-breakpoint-up(md) {
        display: flex;
    }
}

.tabs-wrapper .accordion-header {
    @include media-breakpoint-up(md) {
        display: none;
    }
}

.tabs-wrapper .collapse {
    @include media-breakpoint-up(md) {
        display: block;
    }
}

.tabs-wrapper .tab-content > .tab-pane {
    @include media-breakpoint-down(sm) {
        display: block !important;
        opacity: 1;
    }
}

//
// Styles
//
.tabs-wrapper {
}

//
// Tab styles
//

//
// Accordion
//
.tabs-wrapper .accordion-header {
}

.tabs-wrapper .accordion-header a {
    padding: 10px 15px;
    color: #fff;
    background: var(--primary);
    display: flex;
    align-items: center;
    border: 1px solid var(--border-color);
    border-top: 0;
}

.tabs-wrapper .accordion-icon-wrapper {
    font-size: 30px;
    flex: 0 0 auto;
    width: 46px;
}

.tabs-wrapper .accordion-header a[aria-expanded='true'] {
    background: var(--primary);
    border-color: var(--primary);
    color: #fff;
}

.tabs-wrapper .accordion-body-inner {
    border: 1px solid var(--border-color);
    border-top: 0;
    padding: 20px 20px;

    @include media-breakpoint-up(md) {
        padding: 0;
        border: 0;
    }
}

.accordion-title br {
    display: none;
}

//
// Pills
//
.tabs-wrapper .nav-link {
    @include media-breakpoint-up(md) {
        padding: 10px 15px;
        color: #fff;
        display: flex;
        align-items: center;
        background: var(--primary);
    }
}

.tabs-wrapper .nav-link.active {
    @include media-breakpoint-up(md) {
        background: var(--secondary);
    }
}

.tabs-wrapper .nav-link-icon-wrapper {
    @include media-breakpoint-up(md) {
        font-size: 30px;
        flex: 0 0 auto;
        width: 46px;
    }
}
