.page-top {
    position: relative;

    @include media-breakpoint-up(lg) {
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        grid-gap: 0;
        position: relative;
    }
}

.page-top-content {
    @include media-breakpoint-up(lg) {
        grid-column: 1;
        grid-row: 1;
        position: relative;
        z-index: 2;
        margin-left: var(--side-space);
        display: flex;
        align-items: center;
        padding-top: 110px;
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: 20px;
    }

    @include media-breakpoint-up(xl) {
        width: 50%;
        padding-right: 0;
    }

    @include media-breakpoint-up(xxxl) {
        width: 43%;
        padding-top: 160px;
        padding-bottom: 130px;
    }
}

.page-top:before {
    @include media-breakpoint-up(lg) {
        transform: translateX(-2px);
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: var(--side-space);
        right: calc(var(--side-space) * -1);
        z-index: 1;
        background: linear-gradient(
            to right,
            #496b37 0%,
            rgba(#496b37, 0.5) 100%
        );
    }

    @include media-breakpoint-up(xl) {
        width: 50%;
    }

    @include media-breakpoint-up(xxxl) {
        width: 43%;
    }
}

.page-top-image-container:before {
    content: '';
    display: block;
    position: absolute;
    top: calc(var(--page-top-space) * -1);
    bottom: 0;
    left: 0;
    right: calc(var(--side-space) * -1);
    z-index: -1;
    background: linear-gradient(to right, #496b37 0%, rgba(#496b37, 0.5) 100%);
}

.page-top-text {
    padding-left: var(--side-space);
    padding-right: var(--side-space);

    @include media-breakpoint-up(lg) {
        padding-left: 0;
        padding-right: 5%;
    }
}

.page-top-text .with-stripe:after {
    margin-top: 20px;
    margin-bottom: 30px;

    @include media-breakpoint-up(md) {
        margin-top: 36px;
        margin-bottom: 40px;
    }
}

.page-top-text p {
    @include media-breakpoint-up(xxl) {
        max-width: 80%;
    }
}

.page-top-text p:not(.lead) {
    @include media-breakpoint-up(lg) {
        margin-bottom: 15px;
    }
}

.page-top-text p .btn {
    @include media-breakpoint-up(lg) {
        margin-top: 21px;
    }
}

.page-top-image-container {
    margin-bottom: 25px;
    position: relative;

    @include media-breakpoint-up(lg) {
        grid-column: 1;
        grid-row: 1;
        position: relative;
        z-index: 0;
        margin: calc(var(--page-top-space) * -1) calc(var(--side-space) * -1) 0
            var(--side-space);
        margin-left: var(--side-space);
    }
}

.ccm-page .page-top-image {
    width: 100%;
    height: auto;
    max-width: none;

    @include media-breakpoint-up(lg) {
        height: 100%;
        object-fit: cover;
    }

    @include media-breakpoint-up(xxxl) {
        object-fit: contain;
        height: auto;
    }
}

.breadcrumbs-wrapper {
    @include media-breakpoint-up(lg) {
        position: absolute;
        left: 0;
        top: 30px;
        z-index: 5;
    }

    @include media-breakpoint-up(xxxl) {
        top: 73px;
    }
}

.page-subtitle {
    margin-top: 5px;
    display: block;
    @include fluid(24px, 45px, $force-px: true);
    @include fluid(24px, 45px, 'line-height', $force-px: true);

    @include media-breakpoint-up(md) {
        margin-top: 10px;
    }

    @include media-breakpoint-up(xxl) {
        margin-top: 32px;
    }
}
