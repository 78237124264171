.footer-link-list {
    padding-left: 0;
    margin-bottom: 0;
}

.footer-link-list li {
    list-style: none;
    padding-left: 13px;
    position: relative;
    margin-bottom: 5px;
}

.footer-link-list li:last-child {
    margin-bottom: 0;
}

.footer-link-list li:before {
    list-style: none;
    content: ' ';
    display: block;
    position: absolute;
    top: 10px;
    left: 0;
    background: currentColor;
    width: 3px;
    height: 3px;
    border-radius: 50%;
}

.footer-link-list a:not(:hover) {
    color: var(--link-color-3);
    --link-decoration-color: #fff;
    --link-decoration-color-hover: #fff;
}
