.main-nav-toggle {
    display: flex;
    align-items: center;
    outline: none;

    @include media-breakpoint-up(md) {
        margin-right: 9px;
    }

    @include media-breakpoint-up($top-view-switch) {
        display: none;
    }
}

.main-nav-toggle.hamburger:hover {
    opacity: 1;
}
