.slider-home-wrapper {
    max-width: 1920px;
    margin: 0 auto;
    //position: relative;
    --captions-width: 66%;

    @include media-breakpoint-up(md) {
        --captions-width: 55%;
    }

    @include media-breakpoint-up($top-view-switch) {
        margin-right: calc(var(--side-space) * -1);
        --captions-width: 80%;
    }

    @include media-breakpoint-up(xxl) {
        --captions-width: 55%;
    }

    @include media-breakpoint-up(xxxl) {
        --captions-width: 46%;
    }
}

// .page-wrapper:has(.slider-home-wrapper){
//     &::before{
//         content: url("/application/themes/theme/resources/dist/images/christmas/christmas_garland.webp");
//         z-index: 10;
//         position: absolute;
//         top: 0;
//         left: 0;
//         right: 0;
        
//     }
// }

.slider-home-wrapper .slider-home-container {
    @include media-breakpoint-down(md) {
        padding-left: 0;
        padding-right: 0;
    }

    @include media-breakpoint-up(md) {
        max-width: none;
    }
}

.slider-home {
}

.slider-home .swiper-slide {
    display: grid;
    overflow: hidden;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: minmax(0, 1fr);
    color: var(--body-color);
}

// .slider-home .swiper-slide:first-child {
//     .swiper-slide-captions:before{
//         background: none;
//     }

// }

.slider-home .swiper-slide:hover {
    text-decoration: none;
    color: var(--body-color);
}

.slider-home .swiper-slide img {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    width: 100%;

    @include media-breakpoint-up(xxxl) {
        //width: 1920px;
        max-width: none;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
    }
}

.slider-home .swiper-slide-captions {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    position: relative;
    display: grid;
    justify-items: start;
    align-items: center;
    transition: 0.3s;
    max-width: none;

    @include media-breakpoint-up($top-view-switch) {
        padding-left: 0;
    }

    @include media-breakpoint-up(xxxl) {
        align-items: start;
        //padding-top: 229px;
        padding-top: 179px;
    }
}

.slider-home .swiper-slide-captions:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -2px;
    width: 100%;
    z-index: 10;
    background: linear-gradient(to right, #496b37 0%, rgba(#496b37, 0) 100%);

    @include media-breakpoint-up(md) {
        left: -2px;
        background: linear-gradient(
            to right,
            #496b37 0%,
            rgba(#496b37, 0.75) 100%
        );
        width: var(--captions-width);
    }
}

.slider-home .swiper-slide-title {
    margin-bottom: 0;
    @include fluid(26px, 45px);

    &:first-child{
        @include fluid(16px, 26px);
    }

}


.ccm-page .swiper-slide-big-text {
    @include fluid(60px, 170px, $force-px: true);
    @include fluid(60px, 158px, 'line-height', $force-px: true);
    margin-bottom: 0;
    font-family: $font-family-secondary;


    @include media-breakpoint-only(lg) {
        margin-top: 10px;
        font-size: 70px;
        line-height: 70px;
    }

}

.swiper-slide-big-text {
    &:first-of-type{
        @include fluid(40px, 120px, $force-px: true);
        @include fluid(40px, 124px, 'line-height', $force-px: true);
    }
}

.ccm-page .swiper-slide-small-text {
    display: none;
    font-size: rem-calc(10px);
    line-height: 10px;
    padding-right: 5px;
    //color: #9ebb8e;
    color: #9ebb8e;
    margin-bottom: 0;
    border-radius: 10px;

    @include media-breakpoint-up(md) {
        display: block;
        margin-top: 30px;
    }

    @include media-breakpoint-up(lg) {
    }

    @include media-breakpoint-up(xl) {
        border: 1px solid #9ebb8e;
        border-radius: 10px;
        padding: 17px 20px 20px 17px;
        font-size: rem-calc(12px);
        line-height: 16px;
    }

    @include media-breakpoint-up(xxl) {
        margin-top: 5vh;
    }

    @include media-breakpoint-up(xxxl) {
        //margin-top: 75px;
        margin-right: 107px;
    }
}

.slider-home .swiper-slide-subtitle {
    margin: 20px 0 0 0;
    display: none;

    @include media-breakpoint-up(md) {
        display: block;
        padding-right: 20px;
        margin-bottom: 1rem;
    }

    @include media-breakpoint-up(xxl) {
        margin-top: 40px;
    }
}

.slider-home .swiper-slide-subtitle br {
    display: none;

    @include media-breakpoint-up(sm) {
        display: inline-block;
    }
}

.ccm-page .swiper-slide-subtitle-list {
    display: none;

    @include media-breakpoint-up(md) {
        display: block;
        margin-top: 20px;
        padding-left: 0;
    }

    @include media-breakpoint-up(xxl) {
        margin-top: 40px;
    }

    @include media-breakpoint-up(lg) {
        display: flex;
        flex-wrap: wrap;
    }
}

.ccm-page .swiper-slide-subtitle-list li {
    list-style: none;
    position: relative;
    padding-left: 15px;

    @include media-breakpoint-up(lg) {
        display: inline-flex;
        margin-right: 13px;
    }
}

.ccm-page .swiper-slide-subtitle-list li:before {
    content: ' ';
    display: block;
    position: absolute;
    top: 10px;
    left: 0;
    background: currentColor;
    width: 3px;
    height: 3px;
    border-radius: 50%;
}

.swiper-slide-captions-inner {
    width: 100%;
    position: relative;
    z-index: 10;
    max-width: var(--captions-width);
}

.swiper-slide-captions .btn {
    @include media-breakpoint-down(sm) {
        margin-top: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    @include media-breakpoint-up(sm) {
        margin-top: 13px;
    }
}

//
// captions on right side
//
.slider-home .swiper-slide-captions-right .swiper-slide-captions {
    justify-items: end;
    text-align: right;
    justify-content: end;
}

//
// Caption animation
//
.swiper-slide-captions-inner {
    opacity: 0;
    transition: transform 0.7s 0.7s, opacity 0.7s 0.7s;
    transform: translateY(-100px);
}

.swiper-slide-active .swiper-slide-captions-inner {
    opacity: 1;
    transform: translateY(0);
}

.slider-home .slider-pagination-container {
    @include media-breakpoint-up(md) {
        position: absolute;
        z-index: 20;
        left: auto;
        right: var(--side-space);
        bottom: 5vw;
    }

    @include media-breakpoint-up(lg) {
        right: 15px;
        bottom: 15px;
    }

    @include media-breakpoint-up(xl) {
        bottom: 5vw;
    }

    @include media-breakpoint-up(xxl) {
        bottom: 2vw;
        right: auto;
        left: 0;
    }

    @include media-breakpoint-up(xxxl) {
        //bottom: 238px;
        bottom: 178px;
    }
}
