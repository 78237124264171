.offer-more {
    margin-top: 30px;

    @include media-breakpoint-up(md) {
        margin-top: 50px;
    }

    @include media-breakpoint-up(xxl) {
        margin-top: 80px;
    }
}
