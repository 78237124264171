.gallery-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-gap: 10px;
    margin-bottom: rem-calc(30px);
    margin-top: rem-calc(30px);

    @include media-breakpoint-up(md) {
        grid-gap: 20px;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        margin-bottom: rem-calc(40px);
        margin-top: rem-calc(40px);
    }

    @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media-breakpoint-up(xl) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

.gallery-list-item {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: center;
}

.gallery-list-item-small {
    justify-self: center;
}

.gallery-list-item a {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-column: 1 / -1;
    grid-row: 1 / -1;
}

.gallery-list-item img {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    position: relative;
    z-index: 1;
    width: 100%;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    background: $gray-100;
    border-radius: 10px;
}

// Overlay
.gallery-list-item-overlay {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    background: var(--custom-hover-overlay-bg);
    opacity: 0;
    transition: 0.4s;
    position: relative;
    z-index: 2;
}

// Icon
.gallery-list-item .gallery-list-item-icon-container {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    color: #fff;
    font-size: 30px;
    transition: 0.3s;
}

// Hover
.gallery-list-item a:hover .gallery-list-item-overlay,
.gallery-list-item a:hover .gallery-list-item-icon-container {
    opacity: 1;
}

//
// cztery kolumny - wysokie zdjęcia
//
.gallery-list-four-high {
    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}
