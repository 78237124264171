.ccm-page .sitemap-tree-container {
    position: relative;
}

.ccm-page .sitemap-tree {
    margin: 0 0 30px 0;
    padding: 0;
    list-style-type: none;

    @include media-breakpoint-up(md) {
        display: inline-block;
    }
}

.ccm-page .sitemap-tree li {
    list-style-type: none;
    border-left: 1px dotted #999999;
    margin-left: 11px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}

.ccm-page .sitemap-tree li a {
    display: inline;
    color: var(--body-color);
    border-bottom: 0;
    width: auto;
    padding-left: 32px;
    padding-bottom: 5px;
    padding-top: 5px;
    max-width: initial;
    position: relative;
}

.ccm-page .sitemap-tree li a:before {
    content: '';
    display: block;
    flex: 0 0 auto;
    width: 22px;
    border-top: 1px dotted #999999;
    background: none;
    border-radius: 0;
    height: 1px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

.ccm-page .sitemap-tree li a:hover {
    color: $link-hover-color;
}

.ccm-page .sitemap-tree li ul {
    flex: 0 0 auto;
    width: 100%;
    margin: 0;
    padding: 10px 0 15px 25px;
    display: block;
}

//
// nav home
//
.ccm-page .sitemap-tree li.nav-home {
    position: relative;
    border-left: 0;
    padding-left: 0;
    margin-left: 3px;
}

.ccm-page .sitemap-tree li.nav-home a {
    padding-left: 13px;
}

.ccm-page .sitemap-tree li.nav-home a:before {
    display: none;
}
