.lightbox-image-wrapper {
    margin-bottom: 25px;

    @include media-breakpoint-up(md) {
        margin-bottom: 35px;
    }
}

.lightbox-image-link {
    position: relative;
    display: flex;
    width: fit-content;
}
