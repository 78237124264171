.iwd-image{
    margin: 2rem 0;
    clear: both;
    @include media-breakpoint-up(md){
        margin: 3rem 0;
    }
    & .iwd-items{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 1rem;
        @include media-breakpoint-up(md){
            flex-direction: row; 
        }
    }
    & .iwd-item{
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        text-align: center;
        @include media-breakpoint-up(md){
            width: unset;
            flex-direction: column-reverse; 
        }
    }
    & .iwd-item-text-container{
        width: 100%;
    }
    & .iwd-item-title{
        font-size: 1.25rem;
        font-weight: bold;
    }
    & .iwd-image-container{
        max-width: 90px;
        aspect-ratio: 1;
        border-radius: 50%;
        background-color: white;
        border: 1px solid #158365;
        overflow: hidden;
        padding: 1rem;
        display: grid;
        place-content: center;
        @include media-breakpoint-up(md){
            padding: 1.5rem;
            max-width: 120px;
        }
        & img{
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }
    }
}
.iwd-image.iwd-without-white{
    & .iwd-image-container{
        border-radius: 0;
        padding: 0;
        background-color: transparent;
        border: 0;
    }
}
