.box-list-descriptions {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 10px;
    margin-bottom: 30px;
    margin-top: 30px;
    padding-left: 0;

    @include media-breakpoint-up(md) {
        grid-gap: 20px;
        margin-bottom: 50px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media-breakpoint-up(xl) {
        grid-gap: 20px;
        grid-template-columns: repeat(3, minmax(0, 1fr));
        margin-top: 40px;
    }

    @include media-breakpoint-up(xxl) {
        grid-gap: 20px;
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

.box-list-text {
    display: flex;
    flex: 1;
    background: var(--background-1);
    border: 1px solid var(--border-1);
    color: var(--color-4);
    padding: 16px 26px;
    transition: 0.3s;
    border-radius: 10px;
    font-size: rem-calc(16px);
    line-height: rem-calc(20px);
    align-items: center;

    @include media-breakpoint-down(sm) {
        position: relative;
        padding-left: 45px;
    }

    @include media-breakpoint-up(md) {
        justify-content: center;
        text-align: center;
        min-height: 112px;
    }
}

.box-list-text:not(.branch):nth-child(9) {
    margin-top: 15px;
}

.box-list-text:not(.branch):nth-child(n + 9) {
    background: none;
    color: var(--body-color);
    border: 0;
    grid-column: 1 / -1;
    @extend %custom-ul-item;
    margin-left: 15px;
    margin-bottom: 0;
    padding: 0;
    text-align: left;
    min-height: 0;
    justify-content: left;
}

.box-list-text:before {
    @include media-breakpoint-down(sm) {
        font-family: $font-awesome;
        content: '\f00c';
        display: none;
    }
}

.box-list-text:not(.branch):nth-child(n + 9):before {
    top: 0.45rem;

    @include media-breakpoint-up(md) {
        top: 0.4rem;
    }
}

.box-list-text svg {
    @include media-breakpoint-down(sm) {
        position: absolute;
        left: 17px;
        top: 17px;
        color: var(--primary);
        font-size: 18px;
    }

    @include media-breakpoint-up(md) {
        display: none;
    }
}

.box-list-text-inner p {
    margin-bottom: 0;
}
