.page-template-home main {
    margin-bottom: 0;
}

.page-template-home .page-wrapper {
    padding-top: 0;
}

.news-home-area {
    margin: 70px -500px 53px;
    padding: 40px 500px 50px;
    background: var(--body-bg-2);
    color: var(--body-color-2);

    @include media-breakpoint-up(md) {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 50px;
        align-items: start;
    }

    @include media-breakpoint-up(lg) {
        margin-top: 50px;
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: 70px;
    }

    @include media-breakpoint-up(xxl) {
        margin-bottom: 90px;
        grid-gap: 80px;
        padding: 65px 500px;
    }

    @include media-breakpoint-up(xxxl) {
        grid-gap: 153px;
    }
}

//
// news
//
.news-home-wrapper .page-list-wrapper {
    margin-bottom: 0;
}

.news-home-wrapper h2 {
    margin-bottom: 22px;

    @include media-breakpoint-up(xxxl) {
        margin-top: 47px;
    }
}

.news-home-wrapper h2 a:not(:hover) {
    color: var(--link-color-2);
}

.news-home-wrapper .page-list-item-title {
    font-family: $font-family-sans-serif;
    font-size: rem-calc(20px);
    line-height: 22px;
    font-weight: $font-weight-bold;
    margin-bottom: 0;
}

.news-home-wrapper .page-list-item-title a:hover {
    color: $link-hover-color;
}

.news-home-wrapper .page-list-item-date {
    margin-top: 5px;
    font-size: rem-calc(16px);
    color: var(--secondary);
    margin-bottom: 12px;
}

.news-home-wrapper .page-list-item-description {
    line-height: 20px;
}

.news-home-wrapper .page-list-item-read-more-container {
    margin-top: 24px;

    @include media-breakpoint-up(xxl) {
        margin-top: 34px;
    }
}

.news-home-wrapper-slider {
    position: relative;
    margin-top: 50px;

    @include media-breakpoint-up(md) {
        margin-top: 0;
    }
}

//
// Currencies
//
.currency-home-area{
    position: relative;
}
.currency-home-wrapper{
    border-radius: 1rem;
    overflow: hidden;
}
.currency-home-image-wrapper{
    max-height: 200px;
    & img{
        width: 100%;
        object-fit: cover;
        height: 200px;
    }
}
.currency-home-description{
    position: absolute;
    top: 0;
    left: 0;
    padding: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    width: 100%;
    height: 100%;
    @include media-breakpoint-up(xl) {
        padding: 2rem 4rem;
    }
    & > h2 {
        flex: 1;
    }
    & h2, & p {
        margin-bottom: 0;
    }
}

//
// calculator
//
.home-calculator-area {
    margin: 30px 0 40px;

    @include media-breakpoint-up(xxl) {
        margin-bottom: 68px;
    }
}

.home-calculator-wrapper {
    background: #fff;
    min-height: 480px;
    border-radius: $border-radius;
    padding-bottom: 10px;

    @include media-breakpoint-up($top-view-switch) {
        padding-left: 10px;
    }

    @include media-breakpoint-up(xxxl) {
        padding: 50px 40px 40px 0;
    }
}

.home-calculator-wrapper iframe {
    border: 0;
}

.counters-home-area .counters {
    margin-top: 80px;
    margin-bottom: -20px;

    @include media-breakpoint-down(sm) {
        grid-gap: 35px;
    }

    @include media-breakpoint-up(lg) {
        margin-bottom: 90px;
        margin-top: 50px;
    }

    @include media-breakpoint-up(xxl) {
        margin-top: 60px;
    }
}
