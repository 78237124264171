.ccm-page .custom-hover {
    display: flex;
    position: relative;
    transition: none;
}

.ccm-page .custom-hover-overlay {
    display: flex;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
    transition: 0.4s;
    background: var(--custom-hover-overlay-bg);
    opacity: 0;
}

.ccm-page .custom-hover-overlay-n {
    left: -1px;
    right: -1px;
    top: -1px;
    bottom: -1px;
}

.ccm-page .custom-hover:hover .custom-hover-overlay {
    opacity: 1;
}

.ccm-page .custom-hover .custom-hover-icon-container,
.ccm-page .custom-hover .custom-hover-element-container {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: rem-calc(31px);
    color: #fff;
    opacity: 0;
    transition: 0.3s;
}

.ccm-page .custom-hover .custom-hover-element-container {
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    text-shadow: 1px 1px 1px #00000073;

    @include fluid(16px, 30px);
}

.ccm-page .custom-hover:hover .custom-hover-icon-container,
.ccm-page .custom-hover:hover .custom-hover-element-container {
    opacity: 1;
}

// touch devices
.touch-device .show-on-touch .custom-hover-icon-container,
.touch-device .show-on-touch .custom-hover-element-container,
.touch-device .show-on-touch .custom-hover-overlay {
    opacity: 1;
}
