.ccm-page .job-offer-list-no-pages {
    text-align: center;
}

.ccm-page .job-offer-list-wrapper {
    margin-top: rem-calc(40px);
    color: var(--body-color-2);

    @include media-breakpoint-up(xxl) {
        margin-top: rem-calc(50px);
    }
}

.ccm-page .job-offer-list {
    margin-top: rem-calc(30px);
    margin-bottom: rem-calc(60px);
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 50px;

    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        margin-bottom: rem-calc(80px);
    }

    @include media-breakpoint-up(xl) {
        grid-gap: 80px;
    }

    @include media-breakpoint-up(xxl) {
        margin-top: rem-calc(40px);
        margin-bottom: rem-calc(100px);
    }

    @include media-breakpoint-up(xxxl) {
        margin-bottom: rem-calc(120px);
    }
}

.ccm-page .job-offer-list-item {
    padding: 30px 30px 50px 30px;
    position: relative;
    background: var(--background-1);
    border: 1px solid var(--border-1);
    color: var(--color-4);
    border-radius: 5px;

    @include media-breakpoint-up(xxl) {
        padding: 40px 40px 60px 40px;
    }

    @include media-breakpoint-up(xxl) {
        padding: 55px 50px 75px 50px;
    }
}

.ccm-page .job-offer-list-item-title {
    @include fluid(20px, 30px);
}

.ccm-page .job-offer-list-item-title-link:not(:hover) {
    color: var(--body-color-2);
}

.ccm-page .job-offer-list-item-title-link:hover {
}

.job-offer-list-item-workplace {
}

.job-offer-list-item-read-more-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    transform: translateY(50%);
}
