.box-list-wrapper {
    margin-bottom: 30px;
    margin-top: 30px;

    @include media-breakpoint-up(md) {
        margin-bottom: 50px;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 40px;
    }
}

.ccm-page .box-list-title {
    margin-bottom: 25px;

    @include media-breakpoint-up(lg) {
        margin-bottom: 35px;
    }
}

.box-list-description {
}

.box-list {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 10px;

    @include media-breakpoint-up(pm) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media-breakpoint-up(md) {
        grid-gap: 20px;
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @include media-breakpoint-up(xxl) {
        grid-gap: 20px;
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

.ccm-page .box-list-item {
    display: flex;
}

.ccm-page .box-list-item-link {
    flex: 1;
    background: var(--background-1);
    border: 1px solid var(--border-1);
    padding: 5%;
    color: var(--color-3);
    transition: 0.3s;
    border-radius: 10px;
    display: flex;

    @include media-breakpoint-up(pm) {
        padding: 10%;
        min-height: 160px;
        display: flex;
        flex-direction: column;
    }

    @include media-breakpoint-up(md) {
        min-height: 200px;
        justify-content: stretch;
    }

    @include media-breakpoint-between(lg, xxl) {
        min-height: 160px;
    }
}

.ccm-page .box-list-item-link.active,
.ccm-page .box-list-item-link:hover {
    background: var(--secondary);
    border-color: var(--secondary);
    color: var(--color-1);
}

.box-list-item-inner {
    display: flex;

    @include media-breakpoint-up(pm) {
        display: flex;
        flex-direction: column;
        flex: 1;
    }
}

.box-list-item-icon-container {
    font-size: 30px;
    display: flex;
    align-items: flex-end;
    color: var(--secondary);
    transition: 0.3s;
    flex: 0 0 auto;
    width: 45px;

    @include media-breakpoint-up(pm) {
        height: 60px;
        width: auto;
        font-size: 48px;
        align-items: flex-start;
    }
}

.box-list-item-link.active .box-list-item-icon-container,
.box-list-item-link:hover .box-list-item-icon-container {
    color: var(--color-1);
}

.box-list-item-icon {
    position: relative;
    top: -2px;
}

.ccm-page .box-list-item-title {
    font-size: rem-calc(16px);
    line-height: 20px;
    display: flex;
    align-items: center;
    margin: 0;
    flex: 1;
    font-weight: $font-weight-normal;
    font-family: $font-family-sans-serif;

    @include media-breakpoint-up(pm) {
        padding-top: 20px;
        align-items: flex-end;
    }

    @include media-breakpoint-up(md) {
        font-size: rem-calc(18px);
        line-height: 22px;
    }

    @include media-breakpoint-only(lg) {
        font-size: rem-calc(16px);
        line-height: 20px;
    }
}

.ccm-page .box-list-item-title br {
    @include media-breakpoint-down(ps) {
        display: none;
    }
}

.box-list-item-icon-container .fa-money {
    font-size: 20px;

    @include media-breakpoint-up(pm) {
        font-size: 40px;
    }
}

.box-list-item-icon-container .fa-card {
    font-size: 20px;

    @include media-breakpoint-up(pm) {
        font-size: 36px;
    }
}

.box-list-item-icon-container .fa-percent {
    font-size: 24px;

    @include media-breakpoint-up(pm) {
        font-size: 38px;
    }
}
