.ccm-page .table {
    border-radius: $table-radius;
    margin-bottom: 30px;
    margin-top: 5px;
    font-size: rem-calc(16px);
    line-height: 20px;

    @include media-breakpoint-up(xl) {
        margin-bottom: 40px;
        font-size: rem-calc(18px);
        line-height: 22px;
    }
}

.ccm-page .table thead th {
    vertical-align: top;
    font-weight: $font-weight-bold;
}

.ccm-page .table th,
.ccm-page .table td {
    padding: 0.8rem;
    font-size: 0.85em;
}

.ccm-page .table tbody tr:first-child td:first-child {
    border-top-left-radius: $table-radius;
}

.ccm-page .table tbody tr:first-child td:last-child {
    border-top-right-radius: $table-radius;
}

.ccm-page .table tbody tr:last-child td:first-child {
    border-bottom-left-radius: $table-radius;
}

.ccm-page .table tbody tr:last-child td:last-child {
    border-bottom-right-radius: $table-radius;
}

// if there is thead
.ccm-page .table thead + tbody tr:first-child td:first-child {
    border-top-left-radius: 0;
}

.ccm-page .table thead + tbody tr:first-child td:last-child {
    border-top-right-radius: 0;
}

.ccm-page .table-striped tbody tr:nth-of-type(odd):hover {
    background: $table-accent-bg;
}



.table-credits{
    border: solid 1px #9ebf06;
    margin: 10px;
    width: 100%;
}

@media (max-width: 600px)  {
        .table-credits{
            display:block;
            overflow-x: scroll;
        }
}

.table-credits th{
    background-color: #9ebf06;
    border: solid 1px #fff;
    margin: 5px;
}

.table-credits td{
    border: solid 1px #fff;
}

.table-credits th, .table-credits td {
    padding: 15px;
}