.ccm-page .slider-arrows {
    width: auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
}

.slider-arrows-inner {
    display: flex;
    justify-content: space-between;
    flex: 1;
}

.slider-arrow {
    position: relative;
    z-index: 5;
    width: 30px;
    height: 30px;
    font-size: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    outline: 0;
    transition: .3s;
}

.slider-arrow:not(.swiper-button-disabled):hover {
    color: var(--primary);
}

.slider-arrow-prev {
    left: -30px;

    @include media-breakpoint-up(xl) {
        left: -55px;
    }

    @include media-breakpoint-up(xxxl) {
        left: -55px;
    }
}

.slider-arrow-next {
    right: -30px;

    @include media-breakpoint-up(xl) {
        right: -60px;
    }

    @include media-breakpoint-up(xxxl) {
        right: -55px;
    }
}

.slider-arrow.swiper-button-disabled {
}

.slider-arrow.swiper-button-disabled svg {
    opacity: 0.3;
}

// when not enough slides to slide
.slider-arrow.swiper-button-lock {
    opacity: 0;
    display: none;
}
