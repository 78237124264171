.image-highlight {
    position: relative;
}

.image-highlight img {
    position: relative;
    z-index: 1;
}

.image-highlight:before {
    content: '';
    position: absolute;
    background: linear-gradient(180deg, rgba(#fff, 0.22) 30%, rgba(#fff, 0.27) 100%);
    width: 100%;
    height: 100%;
    z-index: 2;
    left: 0;
    top: 0;
}
