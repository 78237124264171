.ccm-page .btn {
    border-radius: 5px;
    @include media-breakpoint-up(lg) {
        @include button-size(
            rem-calc(11px),
            rem-calc(40px),
            $btn-font-size,
            $btn-line-height,
            $btn-border-radius
        );
    }
}

.ccm-page .btn-primary {
    @include button-variant($secondary, $secondary, #fff, #fff, #fff, #fff);
    color: #fff;
}

.ccm-page .btn-secondary {
    @include button-variant($green-3, $green-3, #fff, #fff, #fff, #fff);
}

.ccm-page .btn-tertiary {
    @include button-variant(
        $secondary,
        $secondary,
        $green-3,
        $green-3,
        $green-3,
        $green-3
    );
    color: #fff;
}

.ccm-page .btn-quaternary {
    @include button-variant(
        $primary,
        $primary,
        $secondary,
        $secondary,
        $secondary,
        $secondary
    );
    color: #fff;
}

.ccm-page .btn-quinary {
    @include button-variant($primary, $primary, #fff, #fff, #fff, #fff);
}
