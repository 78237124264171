.faq-wrapper {
    margin-bottom: 30px;
    margin-top: 30px;
    color: var(--body-color);

    @include media-breakpoint-up(md) {
        margin-bottom: 50px;
    }
}

.faq-entry {
    margin-bottom: 10px;
}

.faq-intro {
    padding: 15px 45px 15px 20px;
    cursor: pointer;
    color: var(--color-4);
    background: var(--background-1);
    border: 1px solid var(--border-1);
    border-radius: 5px;
    transition: 0.3s;
}

.faq-intro.is-open,
.faq-intro:hover {
    background: var(--secondary);
    border-color: var(--secondary);
    color: #fff;
}

.faq-title {
    font-weight: $font-weight-bold;
    position: relative;
}

.faq-counter {
}

.faq-intro .icon-indicator {
    position: absolute;
    right: -30px;
    top: 0;
    bottom: 0;
    margin: auto;
    font-size: 16px;
    color: $primary;
    transition: 0.3s;
    transform: rotate(90deg);
}

.faq-intro.is-open .icon-indicator {
    transform: rotate(270deg);
}

.faq-intro.is-open .icon-indicator,
.faq-intro:hover .icon-indicator {
    color: #fff;
}

.faq-short-description {
    margin-top: 5px;
}

.faq-short-description p {
    font-size: 14px;
    line-height: 16px;
}

.faq-short-description p:last-child {
    margin-bottom: 0;
}

.faq-full-content {
}

.faq-full-content-inner {
    padding: 15px 20px 35px 20px;
    overflow: hidden;
    width: 100%;
    color: var(--body-color);

    @include media-breakpoint-up(md) {
        padding: 40px;
    }
}

.faq-full-content-inner > *:last-child {
    margin-bottom: 0;
}

.faq-full-content-inner ul li {
    @extend %custom-ul-item;
}
