.slider-logo-wrapper {
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;

    @include media-breakpoint-up(md) {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    @include media-breakpoint-up(xxl) {
        padding-top: 23px;
        padding-bottom: 18px;
    }
}

.ccm-page .slider-logo-title {
    font-family: $font-family-sans-serif;
    font-size: rem-calc(20px);

    @include media-breakpoint-up(md) {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        flex: 0 0 auto;
        width: 200px;
    }
}

.slider-logo-container {
    @include media-breakpoint-up(md) {
        display: flex;
    }
}

.slider-logo {
    @include media-breakpoint-up(md) {
        min-width: 0;
        overflow: hidden;
    }
}

.slider-logo-item {
    display: flex;
    height: 80px;
    align-items: center;

    @include media-breakpoint-up(md) {
        height: 99px;
    }
}

.slider-logo-item .slider-logo-image {
    margin: 0 auto;
    //filter: grayscale(1);
    //opacity: 0.5;
    transition: 0.3s;
    max-width: 100%;
    height: auto;
    object-fit: contain;
    width: auto;
}

.slider-logo-item:hover .slider-logo-image {
    filter: grayscale(0);
    opacity: 1;
}
