// -------------------------------------------------------------
// Common
// -------------------------------------------------------------
%vis-animate {
    @include media-breakpoint-up($animation-breakpoint) {
        transition-property: all;
        transition-duration: $animation-transition;
        opacity: 0;
    }
}

%vis-animate-on-screen {
    @include media-breakpoint-up($animation-breakpoint) {
        opacity: 1;
    }
}

html:not(.edit-mode) .vis-animate-container [class*="vis-animate-zoom"],
html:not(.edit-mode) .vis-animate-container [class*="vis-animate-fade"],
html:not(.edit-mode) .vis-animate-container [class*="vis-animate-flip"] {
    transition-timing-function: $transition-timing-function;
}

// -------------------------------------------------------------
// Slide
// -------------------------------------------------------------

html:not(.edit-mode) .vis-animate-slide-left {
    @extend %vis-animate;

    @include media-breakpoint-up($animation-breakpoint) {
        transform: translate3d(300px,0,0);
    }
}

html:not(.edit-mode) .vis-animate-slide-right {
    @extend %vis-animate;

    @include media-breakpoint-up($animation-breakpoint) {
        transform: translate3d(-300px,0,0);
    }
}

html:not(.edit-mode) .vis-animate-slide-up {
    @extend %vis-animate;

    @include media-breakpoint-up($animation-breakpoint) {
        transform: translate3d(0,300px,0);
    }
}

html:not(.edit-mode) .vis-animate-slide-down {
    @extend %vis-animate;

    @include media-breakpoint-up($animation-breakpoint) {
        transform: translate3d(0,-300px,0);
    }
}

html:not(.edit-mode) .on-screen .vis-animate-slide-left,
html:not(.edit-mode) .on-screen .vis-animate-slide-right,
html:not(.edit-mode) .on-screen .vis-animate-slide-up,
html:not(.edit-mode) .on-screen .vis-animate-slide-down {
    @extend %vis-animate-on-screen;

    @include media-breakpoint-up($animation-breakpoint) {
        transform: translateZ(0);
    }
}

// -------------------------------------------------------------
// Zoom
// -------------------------------------------------------------

html:not(.edit-mode) .vis-animate-zoom-in {
    @extend %vis-animate;
    transition-timing-function: $transition-timing-function;

    @include media-breakpoint-up($animation-breakpoint) {
        transform: scale(.6);
    }
}

html:not(.edit-mode) .vis-animate-zoom-in-left {
    @extend %vis-animate;

    @include media-breakpoint-up($animation-breakpoint) {
        transform: translate3d(100px,0,0) scale(.6);
    }
}

html:not(.edit-mode) .vis-animate-zoom-in-right {
    @extend %vis-animate;

    @include media-breakpoint-up($animation-breakpoint) {
        transform: translate3d(-100px,0,0) scale(.6);
    }
}

html:not(.edit-mode) .vis-animate-zoom-in-up {
    @extend %vis-animate;

    @include media-breakpoint-up($animation-breakpoint) {
        transform: translate3d(0,100px,0) scale(.6);
    }
}

html:not(.edit-mode) .vis-animate-zoom-in-down {
    @extend %vis-animate;

    @include media-breakpoint-up($animation-breakpoint) {
        transform: translate3d(0,-100px,0) scale(.6);
    }
}

html:not(.edit-mode) .vis-animate-zoom-out {
    @extend %vis-animate;

    @include media-breakpoint-up($animation-breakpoint) {
        transform: scale(1.2);
    }
}

html:not(.edit-mode) .vis-animate-zoom-out-left {
    @extend %vis-animate;

    @include media-breakpoint-up($animation-breakpoint) {
        transform: translate3d(100px,0,0) scale(1.2);
    }
}

html:not(.edit-mode) .vis-animate-zoom-out-right {
    @extend %vis-animate;

    @include media-breakpoint-up($animation-breakpoint) {
        transform: translate3d(-100px,0,0) scale(1.2);
    }
}

html:not(.edit-mode) .vis-animate-zoom-out-up {
    @extend %vis-animate;

    @include media-breakpoint-up($animation-breakpoint) {
        transform: translate3d(0,100px,0) scale(1.2);
    }
}

html:not(.edit-mode) .vis-animate-zoom-out-down {
    @extend %vis-animate;

    @include media-breakpoint-up($animation-breakpoint) {
        transform: translate3d(0,-100px,0) scale(1.2);
    }
}

html:not(.edit-mode) .on-screen .vis-animate-zoom-in,
html:not(.edit-mode) .on-screen .vis-animate-zoom-in-left,
html:not(.edit-mode) .on-screen .vis-animate-zoom-in-right,
html:not(.edit-mode) .on-screen .vis-animate-zoom-in-up,
html:not(.edit-mode) .on-screen .vis-animate-zoom-in-down,
html:not(.edit-mode) .on-screen .vis-animate-zoom-out,
html:not(.edit-mode) .on-screen .vis-animate-zoom-out-left,
html:not(.edit-mode) .on-screen .vis-animate-zoom-out-right,
html:not(.edit-mode) .on-screen .vis-animate-zoom-out-up,
html:not(.edit-mode) .on-screen .vis-animate-zoom-out-down {
    @extend %vis-animate-on-screen;

    @include media-breakpoint-up($animation-breakpoint) {
        transform: translateZ(0) scale(1);
    }
}

// -------------------------------------------------------------
// Fade
// -------------------------------------------------------------

html:not(.edit-mode) .vis-animate-fade {
    @extend %vis-animate;

    @include media-breakpoint-up($animation-breakpoint) {
        opacity: 0;
    }
}

html:not(.edit-mode) .vis-animate-fade-left {
    @extend %vis-animate;

    @include media-breakpoint-up($animation-breakpoint) {
        opacity: 0;
        transform: translate3d(100px,0,0);
    }
}

html:not(.edit-mode) .vis-animate-fade-right {
    @extend %vis-animate;

    @include media-breakpoint-up($animation-breakpoint) {
        opacity: 0;
        transform: translate3d(-100px,0,0);
    }
}

html:not(.edit-mode) .vis-animate-fade-up {
    @extend %vis-animate;

    @include media-breakpoint-up($animation-breakpoint) {
        opacity: 0;
        transform: translate3d(0,100px,0);
    }
}

html:not(.edit-mode) .vis-animate-fade-down {
    @extend %vis-animate;

    @include media-breakpoint-up($animation-breakpoint) {
        opacity: 0;
        transform: translate3d(0,-100px,0);
    }
}

html:not(.edit-mode) .vis-animate-fade-up-left {
    @extend %vis-animate;

    @include media-breakpoint-up($animation-breakpoint) {
        opacity: 0;
        transform: translate3d(100px,100px,0);
    }
}

html:not(.edit-mode) .vis-animate-fade-up-right {
    @extend %vis-animate;

    @include media-breakpoint-up($animation-breakpoint) {
        opacity: 0;
        transform: translate3d(-100px,100px,0);
    }
}

html:not(.edit-mode) .vis-animate-fade-down-left {
    @extend %vis-animate;

    @include media-breakpoint-up($animation-breakpoint) {
        opacity: 0;
        transform: translate3d(100px,-100px,0);
    }
}

html:not(.edit-mode) .vis-animate-fade-down-right {
    @extend %vis-animate;

    @include media-breakpoint-up($animation-breakpoint) {
        opacity: 0;
        transform: translate3d(-100px,-100px,0);
    }
}

html:not(.edit-mode) .on-screen .vis-animate-fade,
html:not(.edit-mode) .on-screen .vis-animate-fade-left,
html:not(.edit-mode) .on-screen .vis-animate-fade-right,
html:not(.edit-mode) .on-screen .vis-animate-fade-up,
html:not(.edit-mode) .on-screen .vis-animate-fade-down,
html:not(.edit-mode) .on-screen .vis-animate-fade-up-left,
html:not(.edit-mode) .on-screen .vis-animate-fade-up-right,
html:not(.edit-mode) .on-screen .vis-animate-fade-down-left,
html:not(.edit-mode) .on-screen .vis-animate-fade-down-right {
    @extend %vis-animate-on-screen;

    @include media-breakpoint-up($animation-breakpoint) {
        opacity: 1;
        transform: translateZ(0);
    }
}


// -------------------------------------------------------------
// Flip
// -------------------------------------------------------------

// Flip left / right
html:not(.edit-mode) .vis-animate-flip-left {
    @extend %vis-animate;

    @include media-breakpoint-up($animation-breakpoint) {
        transform: perspective(2500px) rotateY(-100deg);
    }
}

html:not(.edit-mode) .vis-animate-flip-right {
    @extend %vis-animate;

    @include media-breakpoint-up($animation-breakpoint) {
        transform: perspective(2500px) rotateY(100deg);
    }
}

html:not(.edit-mode) .on-screen .vis-animate-flip-left,
html:not(.edit-mode) .on-screen .vis-animate-flip-right {
    @extend %vis-animate-on-screen;

    @include media-breakpoint-up($animation-breakpoint) {
        transform: perspective(2500px) rotateY(0);
    }
}

// Flip up / down
html:not(.edit-mode) .vis-animate-flip-up {
    @extend %vis-animate;

    @include media-breakpoint-up($animation-breakpoint) {
        transform: perspective(2500px) rotateX(-100deg);
    }
}

html:not(.edit-mode) .vis-animate-flip-down {
    @extend %vis-animate;

    @include media-breakpoint-up($animation-breakpoint) {
        transform: perspective(2500px) rotateX(100deg);
    }
}

html:not(.edit-mode) .on-screen .vis-animate-flip-up,
html:not(.edit-mode) .on-screen .vis-animate-flip-down {
    @extend %vis-animate-on-screen;

    @include media-breakpoint-up($animation-breakpoint) {
        transform: perspective(2500px) rotateX(0);
    }
}


// -------------------------------------------------------------
// Transition duration and delay
// -------------------------------------------------------------

// Transition durations
html:not(.edit-mode) .ccm-page .t-duration {
    @include media-breakpoint-up($animation-breakpoint) {
        transition-duration: var(--t-duration);
    }
}

// Transition delays
html:not(.edit-mode) .ccm-page .t-delay {
    @include media-breakpoint-up($animation-breakpoint) {
        transition-delay: var(--t-delay);
    }
}

// Transition timing function
html:not(.edit-mode) .ccm-page .t-timing {
    @include media-breakpoint-up($animation-breakpoint) {
        transition-timing-function: var(--t-timing);
    }
}
