.edit-mode .header-top {
    z-index: 99;

    @include media-breakpoint-up(md) {
        flex: 1;
    }
}

.header-top.is-fixed {
    transition: 0.3s, padding-right 0s;
    z-index: 1040;
    background: var(--background-1);
    color: var(--body-color);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    border-bottom: 1px solid var(--border-color);

    @include media-breakpoint-up($top-view-switch) {
        box-shadow: none;
        left: auto;
        bottom: 0;
        right: auto;
        margin: 15px 0 15px 15px;
        width: var(--header-width);
        display: flex;
        background: none;
        align-items: flex-start;
        border-bottom: 0;
        height: calc(100vh - 30px);
        overflow-y: visible;

        @include min-screen-height(700px) {
            margin: 30px 0 30px 30px;
            min-height: calc(100vh - 60px);
        }
    }
}

.header-top > .container {
    @include media-breakpoint-up($top-view-switch) {
        padding-left: 0;
        padding-right: 0;
    }
}

.header-top-container-inner {
    display: flex;
    align-items: center;
    background: var(--background-1);
    transition: 0.3s;
    position: relative;

    @include media-breakpoint-up($top-view-switch) {
        flex-direction: column;
        align-items: initial;
        border-radius: 10px;
        box-shadow: 0 0 5px rgba(#000, 0.3);
    }
}

.logo-area {
    margin: 18px auto 15px 0;
    transition: 0.3s;
    position: relative;
    width: 130px;

    @include media-breakpoint-up(pm) {
        width: 140px;
        margin: 18px auto 18px 0;
    }

    @include media-breakpoint-up(md) {
        width: 182px;
        margin: 25px auto 20px 0;
    }

    @include media-breakpoint-up($top-view-switch) {
        margin-left: auto;

        @include min-screen-height(900px) {
            margin-top: 44px;
            margin-bottom: 40px;
            width: 212px;
        }

        @include min-screen-height(1000px) {
            margin-top: 66px;
            margin-bottom: 60px;
        }
    }
}

.header-side {
    display: flex;
    align-items: center;
    margin-right: 10px;

    @include media-breakpoint-up(pm) {
        margin-right: 15px;
    }

    @include media-breakpoint-up(md) {
        margin-right: 20px;
    }

    @include media-breakpoint-up($top-view-switch) {
        flex-direction: column;
        position: fixed;
        right: calc(var(--space-adjustment-from-side, 0px) + 10px);
        top: calc(50px + var(--space-adjustment-from-top));
        margin-right: 0;

        @include min-screen-height(700px) {
            top: calc(80px + var(--space-adjustment-from-top));
        }
    }

    @include media-breakpoint-up(xl) {
        right: calc(var(--space-adjustment-from-side, 0px) + 10px);
    }

    @include media-breakpoint-up(xxxl) {
        right: calc(var(--space-adjustment-from-side, 0px) + 30px);
    }
}

.desktop-nav-area {
    display: none;

    @include media-breakpoint-up($top-view-switch) {
        display: block;
        width: 100%;
        margin-bottom: auto;
    }
}

.desktop-nav-area {
    display: none;

    @include media-breakpoint-up($top-view-switch) {
        display: block;
    }
}

.contact-top-area {
    @include media-breakpoint-up($top-view-switch) {
        order: 2;
        z-index: 1;
    }
}

.header-login-area {
    display: none;
    order: 3;
    margin: 15px 20px;
    background: var(--secondary);
    border-radius: 10px;
    color: #fff;

    @include media-breakpoint-up($top-view-switch) {
        display: block;
        text-align: center;
        padding: 10px 20px 15px;
        margin: 1.5vh 20px 3vh;

        @include min-screen-height(800px) {
            margin: 20px 20px 30px;
            padding: 20px 20px 20px;
        }

        @include min-screen-height(900px) {
            padding: 28px 20px 20px;
            margin: 26px 20px 30px;
        }
    }
}

.header-login-label {
    @include media-breakpoint-up(lg) {
        font-size: rem-calc(18px);
    }
}

.header-login-label a {
    color: var(--link-color-3);
}

html:not(.contrast) .header-login-label a:hover {
    opacity: 0.6;
}

.header-login-label svg {
    @include media-breakpoint-up(lg) {
        font-size: 24px;
        line-height: 10px;
        margin-right: 8px;
    }
}

.header-login-area .btn {
    padding: 5px 10px;
    border-radius: 5px;
    margin-top: 5px;
    width: 100%;

    @include media-breakpoint-up($top-view-switch) {
        @include min-screen-height(800px) {
            margin-top: 10px;
        }

        @include min-screen-height(1000px) {
            margin-top: 25px;
            padding: 10px 10px;
        }
    }
}

.mm-menu-search-form .btn:hover,
.mm-menu-search-form .btn:focus {
    background: var(--tertiary) !important;
    border-color: var(--tertiary) !important;
    color: #fff !important;
}

.fixed-header-fake-height {
    min-height: 70px;

    @include media-breakpoint-up(pm) {
        min-height: 76px;
    }

    @include media-breakpoint-up(md) {
        min-height: 86px;
    }

    @include media-breakpoint-up(lg) {
        display: none;
    }
}

// Move header when user is logged in
.toolbar-visible .header-top.is-fixed {
    top: 48px;
}
// ---

.edit-mode .header-top.is-fixed {
    position: absolute;
    z-index: 1;
}

.toolbar-visible .header-side {
}
