.employees-block-wrapper {
    margin-top: 25px;
    margin-bottom: 35px;

    @include media-breakpoint-up(md) {
        margin-bottom: 45px;
    }
}

.employees-block-title {
    @include media-breakpoint-up(md) {
        margin-bottom: 30px;
    }
}

.employees-block-description {
    margin-bottom: 25px;
}

.employees {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 10px;

    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        grid-gap: 20px;
    }

    @include media-breakpoint-up(xxl) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

.employee {
    display: flex;
    background: var(--background-1);
    border: 1px solid var(--border-1);
    color: var(--color-4);
    padding: 15px 15px;
    align-items: flex-start;
    border-radius: 6px;

    @include media-breakpoint-up(sm) {
        padding: 20px;
    }

    @include media-breakpoint-up(md) {
        align-items: center;
    }
}

.employee-photo-container {
    flex: 0 0 auto;
    width: 80px;

    @include media-breakpoint-up(md) {
        width: 100px;
    }
}

.ccm-page .employee-photo {
    background: var(--border-color);
}

.employee-data {
    min-width: 0;
}

.employee-info {
    padding-left: 20px;
    min-width: 0;
}
.employees .employee .employee-info .btn.btn-primary{
    padding: 3px 20px;
    margin-top: 5px;
    &:hover{
        border-color: $link-color;
    }
    &:active, &:focus{
        border-color: $primary;
    }
}
.employee-info,
.employee-info a {
}

.employee-info > div .show-number {
    font-size: 11px;
    line-height: 12px;
}

.employee-name {
    margin-bottom: 5px;
    margin-top: -2px;
}

.employee-position {
    margin-bottom: 5px;
    line-height: 16px;
    color: $gray-600;
}

.ccm-page .employee-email {
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;

    @include media-breakpoint-up(md) {
        margin-top: 5px;
    }
}

.ccm-page .employee-email a {
    //color: #fff;

    &:hover,
    &:focus {
        text-decoration: underline;
        color: $primary;
        //color: rgba(#fff, .5);
    }
}

.employee-info .employee-phone {
    margin-top: 5px;
}

.employee-info .employee-phone a {
    margin-top: 5px;

    &:hover,
    &:focus {
        text-decoration: none;
        //color: rgba(#fff, .5);
    }
}

.employee-info .employee-phone .show-number {
    position: relative;
    top: -2px;
    //color: $primary;
    //background: #fff;
    //border: 1px solid #fff;
}
.tooltip-employee{
    &.show{
        opacity: 1;
    }
    color: white;
    & .arrow::before{
        border-top-color: $primary;
        border-bottom-color: $primary;
    }
    & .tooltip-inner{
        box-shadow: 5px 5px 10px 10px #0000004f;
        background-color: $primary;
        max-width: 350px;
        font-size: 15px;
        @include media-breakpoint-up(md){
            max-width: 450px;
        }
        @include media-breakpoint-up(md){
            max-width: 500px;
        }
    }
}