//
// map
//
.map-wrapper {
    height: 350px;

    @include media-breakpoint-up(md) {
        height: 480px;
    }
}

.map-wrapper img {
    object-fit: cover;
    @include media-breakpoint-up(xxl) {
        display: block;
        width: 1920px;
        object-fit: cover;
        max-width: none;
    }
}

.map-wrapper .leaflet-popup {
    margin-bottom: 0;
}

.map-wrapper .leaflet-popup-content-wrapper {
    position: relative;
    border-radius: 0;
    background: var(--background-1);
    color: var(--color-4);
    border: 2px solid #fff;
}

.leaflet-container a.leaflet-popup-close-button {
    right: 10px;
    top: 10px;
}

.map-wrapper .leaflet-popup-tip-container {
}

.leaflet-popup-inner {
    font-family: $font-family-sans-serif;
    font-size: rem-calc(16px);

    @include media-breakpoint-up(md) {
        padding: 10px 20px;
    }
}

.leaflet-popup-inner .leaflet-popup-inner-name {
    font-weight: $font-weight-bold;
    margin-bottom: 5px;
    display: block;

    @include media-breakpoint-up(md) {
        font-size: rem-calc(18px);
    }
}

.leaflet-popup-inner .leaflet-popup-inner-address {
    margin-bottom: 5px;
    display: block;
}

.leaflet-popup-inner .leaflet-popup-inner-centrum {
    margin-bottom: 5px;
    display: block;
}

.leaflet-popup-inner .leaflet-popup-inner-centrum svg {
    position: relative;
    top: -1px;
}

.leaflet-popup-inner-link {
    display: block;
    margin-top: 20px;
}

.leaflet-popup-inner-link a {
    color: var(--body-color-2);
}

.leaflet-popup-inner-link a:hover {
    color: $primary;
    text-decoration: none;
}

.leaflet-popup-inner-link svg {
    width: 50px;
    margin-left: 5px;
}

.leaflet-bar a.leaflet-disabled [aria-hidden='true'] {
    display: block;
}
