.ccm-page .alert {
}

.ccm-page .alert-general {
    @include alert-variant($gray-100, $gray-200, $body-color);

    padding: 25px;
    margin-top: 25px;
    margin-bottom: 25px;

    @include media-breakpoint-up(md) {
        padding: 25px 30px;
        margin-top: 35px;
        margin-bottom: 35px;
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: 45px;
    }

    @include media-breakpoint-up(xxl) {
        padding: 30px 36px;
    }
}
