.branches {
    @include media-breakpoint-up(sm) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media-breakpoint-up(xxl) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

.branch {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    padding: 25px 28px;

    @include media-breakpoint-down(pl) {
        padding-left: 45px;
    }

    @include media-breakpoint-up(xxl) {
        padding: 35px 38px;
    }
}

.branch svg {
    display: block;
    position: absolute;
    left: 16px;
    top: 24px;

    @include media-breakpoint-up(sm) {
        display: none;
    }
}

.branch-name {
    font-weight: $font-weight-bold;
    margin-bottom: 10px;
    @include fluid(18px, 20px);
}

.branch-address {
    margin-bottom: 15px;

    @include media-breakpoint-up(md) {
        margin-bottom: 25px;
    }
}

.branch-hours {
    margin-bottom: 20px;
}

.branch-contact {
}

.branch-contact p {
    margin-bottom: 0;
}

.branch-hours b,
.branch-contact b {
    display: inline-block;
    margin-bottom: 5px;
}

.branch-contact p a {
    margin-bottom: 3px;
    display: inline-block;
}
