.lightbox.lightbox-bg {
    display: inline-flex;
    position: relative;
}

.lightbox.lightbox-bg:before {
    content: '';
    position: absolute;
    background: rgba(#000, 0.5);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transition: 0.4s;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    opacity: 0;
}

.lightbox.lightbox-bg:after {
    font-family: $font-awesome;
    content: '\f00e';
    display: none;
}

.lightbox.lightbox-bg svg {
    position: absolute;
    margin: auto;
    font-size: 30px;
    height: 30px;
    width: 30px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    transition: 0.3s;
}

.lightbox.lightbox-bg svg path {
    fill: #fff;
}

.lightbox.lightbox-bg:hover:before,
.lightbox.lightbox-bg:hover svg {
    opacity: 1;
}


.ccm-page .pull-right {
    @include media-breakpoint-up(sm) {
        float: right;
    }
}

.ccm-page .pull-left {
    @include media-breakpoint-up(sm) {
        float: left;
    }
}
