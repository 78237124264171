//// Always show scrollbar to prevent content jump
//.mm-wrapper_blocking {
//    overflow-y: scroll;
//}

// Fix ablility to move blocks when in edit mode
.edit-mode .mm-page {
    position: static;
    z-index: -1;
}

// ---

// Move menu when toolbar is visible
.toolbar-visible body > .mm-menu_offcanvas {
    --mm-offset-top: 48px;
}

// ---

// Hide the menu until the document is done loading.
.main-nav:not(.mm-menu) {
    display: none;
}

// ---

// Page dimm
[class*='mm-menu_pagedim'].mm-menu_opened ~ .mm-wrapper__blocker {
    transition: 0.4s, background 0.4s cubic-bezier(0.165, 0.84, 0.44, 1),
        z-index 0s;
}

.mm-wrapper_opening
    [class*='mm-menu_pagedim'].mm-menu_opened
    ~ .mm-wrapper__blocker {
    transition: 0.4s, background 0.4s cubic-bezier(0.165, 0.84, 0.44, 1),
        z-index 0s;
}

// ---

// Styles
.mm-searchfield input {
    padding-right: 30px;
    text-overflow: ellipsis;
}

//.mm-btn.mm-btn_close:before {
//    content: "\f00d";
//    font-family: $font-awesome;
//    font-weight: 900;
//    display: none;
//}
//
//.mm-btn.mm-btn_close:after {
//    display: none;
//}

.mm-menu_multiline .mm-listitem__text {
}

.main-nav.mm-menu {
    display: flex;
}

.main-nav.mm-menu > .mm-navbars_top {
    order: 1;
}

.main-nav.mm-menu > .mm-panels {
    order: 2;
}

.mm-menu-search-form {
    display: flex;
    padding: 15px;
    order: 4;

    @include media-breakpoint-up($top-view-switch) {
        order: 3;
        padding: 0 20px 20px;

        @include min-screen-height(1000px) {
            padding-bottom: 35px;
        }
    }
}

.mm-menu-search-form .form-control::placeholder {
    color: #aaa;
}

.mm-menu-search-form .form-control {
    -webkit-tap-highlight-color: transparent;
    border-radius: 5px 0 0 5px;
    border-color: #fff;
    background: #fff;
    border-right: 0;
    color: var(--body-color-2);

    @include media-breakpoint-up($top-view-switch) {
        border-color: $gray-300;
    }

    @include media-breakpoint-up(lg) {
        color: var(--body-color-2);
    }
}

.mm-menu-search-form .form-control:focus {
    @include media-breakpoint-down(md) {
        border-color: #fff;
        background: #fff;
        color: var(--body-color-2);
    }

    @include media-breakpoint-up($top-view-switch) {
        border-color: var(--secondary);
        color: var(--body-color-2);
    }
}

.mm-menu-search-form .btn {
    -webkit-tap-highlight-color: transparent;
    border-radius: 0 5px 5px 0;
    display: flex;
    padding: 5px 15px;
    align-items: center;
}

.mm-menu-search-form .btn:not(:hover) {
    background: var(--secondary);
    border-color: var(--secondary);
}

.mm-menu-search-form .btn svg {
    fill: #fff;
}

// Disable tap background
.main-nav.mm-menu .mm-navbar__btn,
.main-nav.mm-menu .mm-listitem__btn,
.main-nav.mm-menu .mm-listview > li > a {
    background: none;
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
}

.main-nav.mm-menu .mm-listview .mm-listitem__text {
    display: flex;
    align-items: center;
}

.main-nav.mm-menu .main-nav-icon {
    display: flex;
    flex: 0 0 auto;
    width: 40px;
    color: var(--secondary);
    font-size: 24px;
}

.main-nav.mm-menu .main-nav-icon .fa-briefcase {
    font-size: 28px;
}

.main-nav.mm-menu .main-nav-icon .fa-bank {
    font-size: 25px;
    transform: translateX(2px);
}

.main-nav.mm-menu .main-nav-icon .fa-phone {
    transform: translateX(3px);
}

.main-nav.mm-menu .main-nav-icon .fa-user {
    font-size: 23px;
    transform: translateX(3px);
}

.main-nav.mm-menu .mm-listview .mm-listitem__text br {
    display: none;
}
