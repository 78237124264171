.error-404-wrapper {
    text-align: center;
    padding-bottom: 30px;

    @include media-breakpoint-up(lg) {
        padding-top: 50px;
        padding-bottom: 100px;
    }
}

.error-404 {
    font-weight: $font-weight-bold;
    font-size: 120px;
    line-height: 1;
    color: $gray-400;
}

.error-404-buttons .btn {
    display: inline-flex;
    margin: 5px;
}
