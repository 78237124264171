.scrolled .header-top {
    box-shadow: 0 0 5px rgba(#000, 0.3);

    @include media-breakpoint-up($top-view-switch) {
        box-shadow: none;
    }
}

.scrolled .logo-area {
}
