.ccm-page .site-logo {
    margin: 0;
    padding: 0;
    position: relative;
}

.ccm-page .site-logo-inner {
    margin: 0;
    padding: 0;
    line-height: 1;
}

.ccm-page .site-logo-subtitle {
    display: block;
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    bottom: 0;
    white-space: nowrap;
    margin: 0;

    @include media-breakpoint-up(md) {
        left: 100%;
        bottom: auto;
        top: 30px;
    }
}

.ccm-page .site-logo-link {
    display: block;
    position: relative;
}

.ccm-page .site-logo-link:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.ccm-page .site-logo-link object {
    display: block;
    pointer-events: none;
}

//
// svg logo
//
.site-logo .svg-container {
    display: block;
    position: relative;
    height: 0;
}

.site-logo .svg-container svg {
    position: absolute;
    left: 0;
    top: 0;
}
