.news {
    @include clearfix;
}

.page-type-news .page-title {
    @include fluid(34px, 50px, $force-px: true);
    @include fluid(34px, 50px, 'line-height', $force-px: true);
}

.news .news-thumbnail-container {
    margin-bottom: 20px;
    margin-top: 20px;

    @include media-breakpoint-up(pm) {
        float: right;
        max-width: 200px;
        margin-left: 20px;
        margin-bottom: 20px;
        margin-top: 0;
    }

    @include media-breakpoint-up(sm) {
        max-width: 300px;
        margin-left: 30px;
        margin-bottom: 30px;
    }

    @include media-breakpoint-up(xl) {
        margin-left: 50px;
        margin-bottom: 50px;
    }

    @include media-breakpoint-up(xxl) {
        max-width: 500px;
    }
}

.news .news-thumbnail {
    align-self: flex-start;
}

.news .news-date {
    color: rgba(#fff, 0.5);
    font-size: rem-calc(14px);
    margin-bottom: 20px;
}

.news .news-content {
    margin-bottom: 30px;
}

.news .news-gallery-title {
    margin-bottom: 20px;
    margin-top: 30px;
    clear: both;

    @include media-breakpoint-up(md) {
        margin-top: 40px;
        margin-bottom: 30px;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 50px;
        margin-bottom: 30px;
    }
}

.news .news-gallery {
}

.news .gallery-list {
    margin-bottom: 40px;
}

.news .news-see-more-container {
    margin-top: 30px;

    @include media-breakpoint-up(md) {
        margin-top: 40px;
    }

    @include media-breakpoint-up(xl) {
        margin-top: 60px;
    }
}

.page-type-news .breadcrumbs .breadcrumbs-item.active,
.page-type-news .breadcrumbs .breadcrumbs-item:nth-last-child(2):after,
.page-type-news .breadcrumbs .breadcrumbs-item:nth-last-child(2) svg {
    display: none;
}
