.custom-select:invalid {
    color: $input-placeholder-color;

    option {
        color: var(--body-color);
    }
}

.custom-file-label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 100px;
    border-color: var(--border-color-3);
    color: var(--body-color);
}

.custom-file-input:lang(pl) ~ .custom-file-label::after {
    color: var(--body-color);
}

.ccm-page .form-control {
    font-size: 1rem;
}

.ccm-page textarea {
    min-height: 122px;
}

.grecaptcha-badge {
    visibility: hidden;
}

.recaptcha {
    padding-left: $custom-control-indicator-size + $custom-control-gutter;
    font-size: rem-calc(12px);
    line-height: rem-calc(14px);
    color: $gray-600;
}

//.disabled {
//    @extend .form-control:disabled;
//}

.readonly-no-focus:focus {
    background-color: $input-disabled-bg;
    border-color: $input-border-color;
    opacity: 1;
    box-shadow: none;
    caret-color: transparent;
}

@if ($enable-rounded) {
    .invalid-feedback {
        margin-left: $input-padding-x;
    }
}

.form-agreement {
    font-size: 12px;
    line-height: 14px;
    color: $gray-600;

    @include media-breakpoint-up(md) {
        margin-top: 17px;
    }
}

.form-agreement .invalid-tooltip {
    font-size: 100%;
    margin-top: 10px;
    margin-left: 0;
}

//
// Validation with tooltips
//
.needs-validation .form-group {
    position: relative;
}

.invalid-tooltip {
    position: static;

    @include media-breakpoint-up(md) {
        position: absolute;
        right: 48px;
        top: 5px;
        left: auto;
        bottom: auto;
    }
}

.form-agreement .invalid-tooltip {
    position: static;
}

.was-validated .form-agreement :invalid ~ .invalid-tooltip {
    display: inline-flex;
}

.custom-file .invalid-tooltip {
    right: 120px;
}

//$svgIndicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='13.162' height='10.003' viewBox='0 0 13.162 10.003'><path id='icon_check' d='M383.682,715.017,382.2,713.54l-7.05,7.05L372,717.434l-1.478,1.477,4.634,4.632Z' transform='translate(-370.52 -713.54)' fill='red'/></svg>");
//
//.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
//    background-image: escape-svg($svgIndicator);
//}
