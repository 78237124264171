.counters {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 50px;

    @include media-breakpoint-up(sm) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(3, auto);
    }

    @include media-breakpoint-up(lg) {
        grid-template-columns: repeat(2, auto);
    }

    @include media-breakpoint-up(xl) {
        grid-template-columns: repeat(3, auto);
    }

    @include media-breakpoint-up(xxl) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

.counter {
    display: flex;
}

.counter-inner {
    display: flex;
}

.counter-icon-container {
    flex: 0 0 auto;
    width: 90px;
    font-size: 50px;
    line-height: 1;
    color: #9fbf8e;

    @include media-breakpoint-up(xxl) {
        width: 120px;
        font-size: 74px;
    }
}

.counter-number-wrapper {
    font-size: rem-calc(50px);
    display: flex;
    line-height: 1;

    @include media-breakpoint-up(xxl) {
        font-size: rem-calc(70px);
    }
}

.counter-number {
}

.counter-text {
}

.counter-title {
    font-size: rem-calc(20px);
    line-height: 1;
    color: #9fbf8e;
}

.counter-description {
}

// icons
.counter-icon-container svg {
    position: relative;
}

.counter-icon-container .fa-university {
    left: 5px;
}
