.map-poi-wrapper {
    max-width: 1920px;
    @include media-breakpoint-down(ms) {
        margin-left: calc(var(--side-space) * -1);
        margin-right: calc(var(--side-space) * -1);
    }
    @include media-breakpoint-up($top-view-switch) {
        width: 100vw;
        margin-left: calc(
            calc(var(--header-width) + var(--side-space) + 15px) * -1
        );
        @include min-screen-height(700px) {
            margin-left: calc(
                calc(var(--header-width) + var(--side-space) + 30px) * -1
            );
        }
    }
}

.map-area > h2 {
    @include media-breakpoint-up(xxl) {
        margin-bottom: 49px;
    }
}

.map-area h2 a {
    @include media-breakpoint-up(lg) {
        white-space: nowrap;
    }
}

.map-area h2 a:not(:hover) {
    color: var(--body-color);
}

.map-area h2 a span {
    font-size: 20px;
}

.map-area h2 a br {
    display: none;
}
