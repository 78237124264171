.modal-login {
    color: var(--color-4);
}

.modal-login .modal-dialog {
    @include media-breakpoint-up(md) {
        max-width: 800px;
    }
}

.modal-login .modal-header .close {
    position: absolute;
    right: 28px;
}

.modal-login .modal-header {
    padding: 20px 30px;
    padding-bottom: 0;
    border: 0;
}

.modal-login .modal-body {
    padding: 30px;
}

.modal-login h2 {
    font-size: 30px;
    margin: 0;

    @include media-breakpoint-up(md) {
        margin: 0 auto;
    }
}

.login-buttons {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    grid-gap: 10px;

    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

.login-buttons .btn {
    width: 100%;
}
