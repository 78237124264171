.job-offer-form h2 {
    margin-bottom: rem-calc(30px);
}

.job-offer-form h2 + p {
    margin-top: rem-calc(-20px);

    @include media-breakpoint-up(md) {
        margin-bottom: rem-calc(30px);
    }

    @include media-breakpoint-up(xxl) {
        margin-top: rem-calc(-15px);
        margin-bottom: rem-calc(40px);
    }
}

.job-offer-form .form-inputs {
    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media-breakpoint-up(xxl) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

.job-offer-form .form-inputs .form-group:nth-child(5) {
    @include media-breakpoint-between(md, xl) {
        grid-column: 1 / -1;
    }
    @include media-breakpoint-up(xxl) {
        grid-column: 2 / -1;
    }
}

.job-offer-form .form-inputs .form-group:nth-child(6) {
    @include media-breakpoint-up(md) {
        grid-column: 1 / -1;
    }
}

.job-offer-form .invalid-tooltip {
    @include media-breakpoint-down(lg) {
        position: static;
    }

    @include media-breakpoint-up(xxl) {
        position: static;
    }
}

.job-offer-form .custom-file {
    font-size: 1rem;
}

.job-offer-form .custom-file-label {
    //color: $input-placeholder-color;
}

.job-offer-form .custom-file,
.job-offer-form .custom-file-label,
.job-offer-form .custom-file-input,
.job-offer-form .custom-file-label:after {
    height: $form-floating-height;
    line-height: $form-floating-height;
    padding-top: 0;
    padding-bottom: 0;
}
