//body {
//    @include media-breakpoint-up(xxl) {
//        font-size: 1.125rem;
//    }
//}

.ccm-page ::selection {
    text-shadow: none;
    background: rgba($selection-bg, 0.99);
    color: $selection-color;
}

.ccm-page b,
.ccm-page strong {
    font-weight: $font-weight-bold;
}

.ccm-page a {
    transition: 0.3s;
}

.ccm-page img {
    max-width: 100%;
    height: auto;
}

.ccm-page h1 {
    @include fluid(34px, 90px, $force-px: true);
    @include fluid(34px, 90px, 'line-height', $force-px: true);
}

.ccm-page h2 {
    @include fluid(32px, 45px);
}

.ccm-page h3 {
    @include fluid(30px, 42px);
}

.ccm-page h4 {
    @include fluid(28px, 39px);
}

.ccm-page h5 {
    @include fluid(26px, 37px);
}

.ccm-page h6 {
    @include fluid(24px, 35px);
}

.ccm-page {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @include media-breakpoint-up(lg) {
            margin-bottom: $headings-margin-bottom-desktop;
        }
    }
}

.ccm-page main {
    p,
    ul,
    ol {
        + {
            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                margin-top: $spacer;

                @include media-breakpoint-up(md) {
                    margin-top: $spacer * 1.2;
                }

                @include media-breakpoint-up(lg) {
                    margin-top: $spacer * 1.4;
                }
            }
        }
    }
}

.ccm-page p {
    margin-bottom: $paragraph-margin-bottom-mobile;

    @include media-breakpoint-up(xl) {
        margin-bottom: $paragraph-margin-bottom-desktop;
    }
}

.ccm-page .lead {
    @include fluid(18px, 20px);
}

.ccm-page hr {
    border-top-color: var(--hr-bg);
    @include media-breakpoint-up(md) {
        margin: rem-calc(35px) 0 rem-calc(32px);
    }
    @include media-breakpoint-up(xl) {
        margin: rem-calc(45px) 0 rem-calc(42px);
    }
    @include media-breakpoint-up(xxl) {
        margin: rem-calc(55px) 0 rem-calc(52px);
    }
}

//
// Custom lists placeholders
//

// Common (ol and ul)
%custom-list-margins {
    margin-top: 5px;

    @include media-breakpoint-up(xl) {
        margin-top: 10px;
    }
}

%custom-list-item {
    position: relative;
    margin-bottom: 5px;

    @include media-breakpoint-up(xl) {
        margin-bottom: 10px;
    }
}

// Ordered list
%custom-ol-no-margin {
    padding-left: 1em;
}

%custom-ol-item {
    @extend %custom-list-item;
}

// Unordered list
%custom-ul-no-margin {
    padding-left: 1em;
}

%custom-ul-item {
    list-style: none;

    @extend %custom-list-item;

    &::before {
        content: ' ';
        display: block;
        width: 5px;
        height: 5px;
        position: absolute;
        top: rem-calc(8px);
        left: rem-calc(-14px);
        background: var(--secondary);

        @include media-breakpoint-up(xxl) {
            top: rem-calc(8px);
        }
    }
}

//
// Custom lists styles
//

// Common (ol and ul)
.ccm-page .custom-ul li > ul,
.ccm-page .custom-ul li > ol,
.ccm-page .custom-ol li > ul,
.ccm-page .custom-ol li > ol {
    @extend %custom-list-margins;
}

// List common
main ol + p,
main ul + p {
    margin-top: 30px;

    @include media-breakpoint-up(xl) {
        margin-top: 40px;
    }
}

main p + ul,
main p + ol {
    margin-top: -5px;
}

// Ordered list
.ccm-page .custom-ol li {
    @extend %custom-ol-item;
}

.ccm-page .custom-ol-no-margin {
    @extend %custom-ol-no-margin;
}

// Unordered list
.ccm-page .custom-ul li {
    @extend %custom-ul-item;
}

.ccm-page .custom-ul-no-margin {
    @extend %custom-ul-no-margin;
}

// Link decoration
%link-decoration-common {
    text-decoration: none;
    display: inline;
    padding-bottom: 0px;
    transition: 0.3s linear;
    background: linear-gradient(
        to bottom,
        var(--link-decoration-color) 0%,
        var(--link-decoration-color) 98%
    );
    background-repeat: no-repeat;
    background-position: left 100%;
}

%link-decoration {
    @extend %link-decoration-common;
    background-size: 100% 1px;

    &:hover {
        --link-decoration-color: var(--link-decoration-color-hover);
    }
}

%link-decoration-r {
    @extend %link-decoration-common;
    background-size: 0 1px;

    &:hover {
        background-size: 100% 1px;
        --link-decoration-color: var(--link-decoration-color-hover);
    }
}

.ccm-page .link-decoration {
    @extend %link-decoration;
}

.ccm-page .link-decoration-r {
    @extend %link-decoration-r;
}

%heading-lead {
    @include fluid(26px, 54px);
    @include fluid(30px, 64px, 'line-height');
}

.ccm-page .heading-lead {
    @extend %heading-lead;
}

%center-content {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    &.highlight {
        text-shadow: 0 0 20px var(--scroll-higlight-color);
        transition: 2s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
    }
}

.highlight.highlight-fade-out {
    text-shadow: 0 0 20px transparent;
}

.text-primary {
    color: var(--primary);
}

.text-secondary {
    color: var(--secondary);
}

.ccm-page .with-stripe {
    position: relative;
}

.ccm-page .with-stripe:after {
    content: '';
    display: block;
    width: 50px;
    height: 2px;
    background: var(--secondary);

    margin-top: 15px;
    margin-bottom: 25px;

    @include media-breakpoint-up(md) {
        margin-top: 20px;
        margin-bottom: 35px;
    }
}

.ccm-page .list-lower-latin {
    list-style-type: lower-latin;
}

main p a[href^='mailto:'] {
    @extend %link-decoration-r;
}

main p a:not([href^='tel:']):not(.btn),
.main-content > ul li a:not([href^='tel:']):not(.btn),
.main-content > ol li a:not([href^='tel:']):not(.btn) {
    @extend %link-decoration-r;
}

.ccm-page .text-small {
    font-size: 0.8rem;
}

.ccm-page .buttons-group {
    margin: -10px -10px 15px;

    @include media-breakpoint-up(xl) {
        margin: -10px -10px 25px;
    }
}

.buttons-group .btn {
    display: inline-flex;
    margin: 10px 8px;
}

.ccm-page .heading-subtitle {
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    @include fluid(22px, 28px);
}

.ccm-page .rounded-corners {
    border-radius: 8px;
}

.visually-hidden {
    display: none;
}