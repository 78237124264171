.swiper-news{
    border-radius: $border-radius;
}
.swiper-news .swiper-slide {
    display: grid;
    &:hover{
        .swiper-image::after{
            opacity: 1;
        }
    }
}

.swiper-news .swiper-wrapper{
    display: grid;
    grid-auto-flow: column;
}

.swiper-news .swiper-image {
    grid-row: 1;
    grid-column: 1;
    border-radius: $border-radius;
    position: relative;
    & img{
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
    &::after{
        content: "";
        display: block;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #0000006b;
        transition: 0.3s;
    }
}

.swiper-news .swiper-description {
    grid-column: 1;
    grid-row: 1;
    position: relative;
    z-index: 10;
    padding: 25px;
    color: var(--body-color);
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;


    @include media-breakpoint-up(xl) {
        padding: 35px;
    }

    @include media-breakpoint-up(xxl) {
        padding: 47px;
    }
}

.swiper-news .swiper-description .btn {
    margin-top: 10px;
    @include media-breakpoint-up(xxxl) {
        margin-top: 30px;
    }
}
//pagination
.news-home-wrapper-slider .swiper-pagination-news{
    bottom: -30px;

    & .swiper-pagination-bullet-active{
        background: $primary;
    }
}