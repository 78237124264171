body {
    overflow-y: overlay;
    background: var(--body-bg);
    color: var(--body-color);
}

.ccm-page {
    overflow: hidden;
    max-width: 1920px;
    margin: 0 auto;
}

.page-wrapper {
    @include media-breakpoint-up($top-view-switch) {
        margin-left: calc(var(--header-width) + 15px);

        @include min-screen-height(700px) {
            margin-left: calc(var(--header-width) + 30px);
        }
    }
}

main {
    margin-bottom: rem-calc(50px);

    @include media-breakpoint-up(md) {
        margin-bottom: rem-calc(70px);
    }

    @include media-breakpoint-up(xxl) {
        margin-bottom: rem-calc(90px);
    }

    @include media-breakpoint-up(xxxl) {
        margin-bottom: rem-calc(106px);
    }
}

.main-content {
    margin-top: 30px;

    @include media-breakpoint-up(xl) {
        margin-top: 50px;
    }
}
