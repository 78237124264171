.footer-bottom {
    color: var(--body-color);
}

.footer-bottom > .container {
    position: relative;
    width: 100%;
    padding-top: rem-calc(36px);
    padding-bottom: rem-calc(36px);
    display: flex;
    align-items: center;
    font-size: rem-calc(15px);
}

.footer-bottom > .container:before {
    content: ' ';
    display: block;
    position: absolute;
    left: var(--side-space);
    right: var(--side-space);
    top: -1px;
    height: 1px;
    background: var(--border-color-2);
}

.footer-bottom-text {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(sm) {
        flex-direction: row;
    }
}

.silnet-logo-container {
    margin-left: auto;
}

.footer-bottom-copyright {
    margin-bottom: 20px;

    @include media-breakpoint-up(sm) {
        margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 0 rem-calc(20px);
    }

    @include media-breakpoint-up(md) {
        margin-bottom: 0;
    }
}

.footer-bottom-links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include media-breakpoint-up(sm) {
        margin-left: 30px;
    }

    @include media-breakpoint-up(lg) {
        flex-direction: row;
    }
}

.footer-bottom-links a {
    color: var(--link-color-3);
    margin-right: rem-calc(36px);
}

.footer-bottom-links a:hover,
.footer-bottom-links a:focus {
    color: var(--color-2);
}

.footer-bottom-links a:last-of-type {
    margin-right: 0;
}
