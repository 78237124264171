.ccm-page .back-to-top {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background: var(--primary);
    font-size: 36px;
    color: #fff;
    position: fixed;
    right: $grid-gutter-width/2;
    bottom: $grid-gutter-width/2;
    z-index: 100;
    transition: 0.3s;
    opacity: 0;
    box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12),
        0 1px 1px 0 rgba(0, 0, 0, 0.24);
    cursor: pointer;
    pointer-events: none;

    @include media-breakpoint-up(md) {
        right: var(--back-to-top-right);
    }
}

.back-to-top:hover {
    background: lighten($primary, 10%);
}

.back-to-top .fa-angle-up {
    position: relative;
    top: -1px;
}

// Show button when page is scrolled
.scrolled .back-to-top {
    opacity: 1;
    pointer-events: all;
}

// Move when user scrolls near the end of the page
.back-to-top.pushed {
    @include media-breakpoint-up(ps) {
        transform: translateX(-50px);
        bottom: 70px;
    }

    @include media-breakpoint-up(sm) {
        bottom: 38px;
    }

    @include media-breakpoint-up(lg) {
        transform: translateX(50px);
    }

    @include media-breakpoint-up(xl) {
        bottom: 33px;
    }

    @include media-breakpoint-up(xxl) {
        transform: translateX(-50px);
    }
}
